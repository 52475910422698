import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { withRouter } from 'react-router-dom'
import Endpoints from './Endpoints'

class SeaStarPdGitlabOwners extends React.Component {
  constructor(props) {
    super(props)
    const gitlabOwners = new Map();
    (this.props.pdGitlabConfig != undefined ? this.props.pdGitlabConfig.gitlabOwners : []).forEach(userObjectId => gitlabOwners.set(userObjectId, true))
    this.state = {
      gitlabOwners: gitlabOwners,
      setGitlabOwnersCommandSuccess: undefined,
      setGitlabOwnersCommandError: undefined
    }
  };

  renderGitlabOwnerSelector = (cellContent, row) => {
    const checkedAttrib = {}
    checkedAttrib.defaultChecked = (this.state.gitlabOwners.has(row.objectId))
    return <Form.Check type="checkbox" label="Gitlab Owner" disabled={!this.props.currentUserIsOwner}
                       onClick={(event) => {
                         const newOwners = this.state.gitlabOwners
                         if (newOwners.has(row.objectId)) {
                           newOwners.delete(row.objectId)
                         } else {
                           newOwners.set(row.objectId, true)
                         }
                         this.setState({ gitlabOwners: newOwners })
                       }} {...checkedAttrib}/>
  }

  renderPdDataOwner = (cellContent, row) => {
    const dataOwner = this.props.pdOwner ? this.props.pdOwner : []
    if (dataOwner.some(owner => owner.objectId === row.objectId)) {
      return (<div><FontAwesomeIcon icon={'check'}/></div>)
    } else {
      return <React.Fragment/>
    }
  }

  saveGitlabOwners() {
    this.sendUpdateGitlabConfigForPdRequest(this.state.gitlabOwners).then(response => {
      if (response && response.error && response.error.length >= 0) {
        // Error flag set
        this.setState({ setGitlabOwnersCommandSuccess: false, setGitlabOwnersCommandError: response.error })
      } else {
        this.setState({ setGitlabOwnersCommandSuccess: true, setGitlabOwnersCommandError: undefined })
      }
    })
  }

  sendUpdateGitlabConfigForPdRequest = async (gitlabOwners) => {
    console.log(gitlabOwners)
    console.log(Array.from(gitlabOwners.keys()))
    try {
      const response = await fetch(Endpoints.setGitlabConfigForPd + this.props.pdId, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ gitlabOwners: Array.from(gitlabOwners.keys()) })
      })

      if (!response.ok) {
        if (response.status === 403) {
          return { error: 'Backend returned that you have no permissions to set gitlab owners for this PD.' }
        }
        if (response.status === 500) {
          return { error: 'Internal Error. Please report to SeaStar Admins.' }
        } else {
          return { error: 'Server did not confirm request. Response is: ' + response.status }
        }
      }

      return await response.json()
    } catch (error) {
      console.error(error)
      return { error: 'Server did not confirm request. Error is: ' + error }
    }
  }

  render() {
    const dataMember = this.props.pdMember ? this.props.pdMember : []
    const allMembers = [].concat(dataMember) // owner already in members array

    const headerStyle = { verticalAlign: 'top' }
    const columnStyle = { verticalAlign: 'middle' }

    let errorResult = ''
    if (this.state.setGitlabOwnersCommandError != undefined) {
      errorResult = <div style={{ fontWeight: 'bold' }}>An error occurred while updating
        owners: {this.state.setGitlabOwnersCommandError}<br/><br/></div>
    }

    let successResult = ''
    if (this.state.setGitlabOwnersCommandSuccess) {
      successResult = <div style={{ fontWeight: 'bold' }}>Gitlab owners have been updated successfully.<br/><br/></div>
    }

    const columns = [
      {
        id: 'userName',
        text: 'Name',
        dataField: 'displayName',
        sort: true,
        headerAlign: 'center',
        headerSortingStyle: { backgroundColor: 'lightgray' },
        headerStyle: headerStyle,
        style: columnStyle
      },
      {
        id: 'pdOwner',
        text: 'PD Data Owner',
        dataField: '',
        formatter: this.renderPdDataOwner,
        sort: true,
        headerAlign: 'center',
        headerSortingStyle: { backgroundColor: 'lightgray' },
        headerStyle: () => {
          return {
            verticalAlign: headerStyle.verticalAlign,
            width: '15%'
          }
        },
        style: columnStyle
      },
      {
        id: 'userGitlabOwnerSetting',
        text: 'Gitlab Owner',
        dataField: '',
        formatter: this.renderGitlabOwnerSelector,
        sort: false,
        headerAlign: 'center',
        headerSortingStyle: { backgroundColor: 'lightgray' },
        headerStyle: () => {
          return {
            verticalAlign: headerStyle.verticalAlign,
            width: '10%'
          }
        },
        style: columnStyle
      }
    ]

    return <React.Fragment>
      {errorResult}
      {successResult}
      <BootstrapTable
        bootstrap4
        keyField='objectId'
        data={allMembers}
        columns={columns}
        noDataIndication={() => { return <div>No data loaded.</div> }}
        defaultSorted={[{
          dataField: 'displayName',
          order: 'asc'
        }]}
      />
      <OverlayTrigger
        key={"gitlabChangeGroupOwnerButton"}
        trigger={"hover"}
        placement="top"
        overlay={<Tooltip id={"gitlabChangeGroupOwnerButtonTooltip-top"}>{(this.props.currentUserIsOwner) ? "Save Gitlab Group Owner" : "You are not an Owner of this PD and cannot change the Gitlab Group Owner"}</Tooltip>}
      >
					<span className="d-inline-block">
						<Button
              className="btn-sm"
              variant="dark"
              text="light"
              disabled={!this.props.currentUserIsOwner}
              style={(this.props.currentUserIsOwner) ? {} : { pointerEvents: 'none' }}
              onClick={(event) => {
                event.stopPropagation()
                this.saveGitlabOwners()
              }}><FontAwesomeIcon icon="save"/> Save</Button>
					</span>
      </OverlayTrigger>
    </React.Fragment>
  }
}

export default withRouter(SeaStarPdGitlabOwners)