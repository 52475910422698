import "moment/min/locales"
import React, {Fragment} from 'react';
import {Col, Row, Container, Card} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "./react-bootstrap-table-overwrites.css"
import InfoBox from "./InfoBox";
import Authentication from "./Authentication";

export class SeaStarAwsAccounts extends React.Component {
    constructor(props) {
        super(props);
        props.selectMainMenuItem('my-resources-dropdown');
        this.state = {
            data:props.data,
            locale: window.navigator.language
        };
    };

    componentDidMount = async () => {
    };

    prepareDataForDisplay = (data) => {
        const preparedData = [];
        if (data && data.pds) {
            for (let index = 0; index < data.pds.length; index++) {
                let datum = data.pds[index];
                if (datum.awsAccounts !== undefined) {
                    for (let indexJ = 0; indexJ < datum.awsAccounts.length; ++indexJ) {
                        let preparedDatum = {
                            pdId: datum.pdId,
                        };

                        if (datum.masterData !== undefined) {
                            preparedDatum.productName = datum.masterData.productName;
                        } else {
                            preparedDatum.productName = 'Unknown';
                        }
                        preparedDatum.awsAccount = datum.awsAccounts[indexJ];
                        preparedData.push(preparedDatum);
                    }
                }
            }
        }
        return preparedData;
    };

    onNoDataLoaded = () => {
        return (<div>No Data Available</div>)
    };

    formatBudgetWarnLevelHeader = (column, colIndex, { sortElement } ) => {
        return (
            <Fragment>
                { column.text } &nbsp;
                <InfoBox
                    key={"awsAccount.budgetInfoTooltip"}
                    trigger={"click"}
                    placement={"bottom"}
                    title={column.text}
                    documentationEntity="DescriptionOfBudgetsOfAwsAccounts" />
                { sortElement }
            </Fragment>
        )
    };

    formatBudgetWarnLevels = (cellContent, row) => {
        return (<Fragment>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <strong>Remind:</strong> ${row.awsAccount.budgetRemindDollar}
                    </Col>
                    <Col>
                        <strong>Warn:</strong> ${row.awsAccount.budgetWarnDollar}
                    </Col>
                    <Col>
                        <strong>Escalate:</strong> ${row.awsAccount.budgetEscalateDollar}
                    </Col>
                </Row>
            </Container>
        </Fragment>);
    };

    render(){
        const headerStyle = {verticalAlign: "top"};
        const columnStyle = {verticalAlign: "middle"};

        const columns = [{
            id: "pdName",
            text: 'Product Name',
            dataField: "productName",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle
        },{
            id: "awsAccountName",
            text: 'AWS Account Name',
            dataField: "awsAccount.name",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle,
            style: columnStyle
        }, {
            id: "awsAccountId",
            text: "AWS Account ID",
            dataField: "awsAccount.awsAccountId",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle,
            style: columnStyle
        },{
            id: "budgetWarnLevels",
            text: "Budget Warn Levels",
            dataField: "awsAccount.budgetRemindDollar",
            headerFormatter: this.formatBudgetWarnLevelHeader,
            formatter: this.formatBudgetWarnLevels,
            sort: false,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle,
            style: columnStyle
        }];

        // add pdId for IT users
        let hasITRole = Authentication.hasRole("it")
        if(hasITRole){
            const pdIdColumn =
                {
                    id: "pdId",
                    text: 'Product Id',
                    dataField: "pdId",
                    sort: true,
                    sortFunc: (leftPdIdString, rightPdIdString, order, dataField, rowA, rowB) => {
                        if(leftPdIdString === undefined){
                            return -1;
                        }
                        if(rightPdIdString === undefined){
                            return 1;
                        }

                        let pdIdPrefix = "pd:";
                        if(leftPdIdString.length >= pdIdPrefix.length && leftPdIdString.length >= pdIdPrefix.length){
                            let leftId = leftPdIdString.slice(pdIdPrefix.length);
                            let rightId = rightPdIdString.slice(pdIdPrefix.length);
                            if (order === 'asc'){
                                return leftId - rightId;
                            } else{
                                return rightId - leftId;
                            }
                        }

                        return 0;
                    },
                    headerAlign: 'center',
                    headerSortingStyle:{backgroundColor:"lightgray"},
                    headerStyle: headerStyle
                }
            columns.unshift(pdIdColumn);
        }

        return (<div style={{margin: "1vh 1vw"}}>
            {
            <Container fluid={true}>
                <Card style={{border: "none"}}>
                    <Card.Body>
                        <Card.Title>
                            <div className={"d-flex align-items-center"}>
                                <div className={"d-flex flex-grow-1 align-items-center"}>
                                </div>
                            </div>
                        </Card.Title>
                        <BootstrapTable
                            bootstrap4
                            keyField='awsAccount.azureAdApplicationId'
                            data={ this.prepareDataForDisplay(this.state.data) }
                            columns={ columns }
                            noDataIndication={ this.onNoDataLoaded }
                            defaultSorted={[
                                hasITRole?
                                    {
                                        dataField: 'pdId',
                                        order: 'asc'
                                    }:{
                                        dataField: 'productName',
                                        order: 'asc'
                                    }
                            ]}
                        />
                    </Card.Body>
                </Card>
            </Container>
            }
        </div>);
        }
    }
