import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    withRouter
} from 'react-router-dom';
import Login from './Login';
import Authentication from './Authentication'
import { LinkContainer } from 'react-router-bootstrap';
import {
    Navbar,
    NavDropdown,
    Nav
} from 'react-bootstrap';
import {Spinner, Fade} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MailHandler from "./MailHandler";

import {NotFoundPage} from "./NotFoundPage";
import SeaStarDocumentation from "./SeaStarDocumentation";
import {SeaStarPdOverview} from "./SeaStarPdOverview";
import {SeaStarPdDetails} from "./SeaStarPdDetails";
import{SeaStarDomains} from "./SeaStarDomains"
import{SeaStarAwsAccounts} from "./SeaStarAwsAccounts"
import Endpoints from "./Endpoints";
import {fetchDocumentationEntities} from "./DocumentationEntities";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => {
        Authentication.handleUserLoggedIn(props.location);
        const sessionIsActive = Authentication.isSessionActive();

        if (sessionIsActive){
            return (<Component {...props} />)
        }else {
            return (<Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} /> )
        }
    }} />
);

class MainMenuUnboundToRouter extends React.Component {
    handleNavItemSelect(eventKey, event) {
        event.preventDefault();
        switch(eventKey){
            case "logout": {
                Authentication.logout();
                break;
            }
            case "requestNewSeaStarPd":{
                MailHandler.mailtoItToRequestNewSeaStarPd();
                break;
            }
            case "sendFeatureRequest": {
                MailHandler.sendFeatureRequest();
                break;
            }
            case "sendBugReport": {
                MailHandler.sendBugReport();
                break;
            }
            default: {
                //do noting
                break;
            }
        }
    }

    render() {
        return (
            <Navbar fluid={"true"} className={"sticky-top mainmenu-height"} style={{zIndex:"999"}} collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand >
                    <img
                        src={process.env.PUBLIC_URL + '/img/PIXIDA_Logo_White_Red_RGB.png'}
                        height="30"
                        className="d-inline-block align-center"
                        alt="Pixida Logo"
                    />
                    <strong> Sea&nbsp;Star&nbsp;Dashboard</strong>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className="p-2">
                        <LinkContainer to="/myseastarpds"><Nav.Link active={this.props.activeItem === 'myseastarpds'}><FontAwesomeIcon icon="list" /> My Products</Nav.Link></LinkContainer>
                    </Nav>
                    <Nav className="p-2">
                        <NavDropdown title={
                                                            <span>
                                                                <FontAwesomeIcon icon="list" /> My Resources
                                                            </span>
                                                            }
                                                        id="my-resources-dropdown"
                                     active={this.props.activeItem === 'my-resources-dropdown'}>
                                <LinkContainer to="/myseastardomains"><NavDropdown.Item><FontAwesomeIcon icon="server"/> Domains</NavDropdown.Item></LinkContainer>
                                <LinkContainer to="/myseastarawsaccounts"><NavDropdown.Item><FontAwesomeIcon icon={["fab","aws"]}/> Accounts</NavDropdown.Item></LinkContainer>
                        </NavDropdown>
                    </Nav>
                    <Nav className="ml-auto p-2">
                        <LinkContainer to="/docs"><Nav.Link active={this.props.activeItem === 'documentation'}><FontAwesomeIcon icon="book" /> Documentation</Nav.Link></LinkContainer>
                    </Nav>
                    <Nav onSelect={this.handleNavItemSelect}>
                        <Nav.Link eventKey={"requestNewSeaStarPd"}><FontAwesomeIcon icon="envelope" /> Request new SeaStar PD</Nav.Link>
                        <NavDropdown id="feedback-nav-dropdown" title={
                            <span>
							    <FontAwesomeIcon icon="comment-dots" /> Feedback
						    </span>}
                        >
                            <NavDropdown.Item eventKey={"sendFeatureRequest"} active={false}><FontAwesomeIcon icon="comment-dots" /> Feature Request</NavDropdown.Item>
                            <NavDropdown.Item eventKey={"sendBugReport"} active={false}><FontAwesomeIcon icon="bug" /> Bug Report</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown id="user-nav-dropdown" title={
                            <span>
							    <FontAwesomeIcon icon="user" /> {Authentication.getUserName()}
						    </span>}
                        >
                            <NavDropdown.Item eventKey="logout" active={false}><FontAwesomeIcon icon="sign-out-alt" /> Sign out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const MainMenu = withRouter(MainMenuUnboundToRouter);

class RootUnboundToRouter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logoutOccurred: false,
            sessionEnded: false,
            userProfile: {},
            activeMainMenuItem: false,
            data: [],
            isLoadingData: false,
            fadeInWaitApology: false
        };
    }

    componentDidMount = async () => {
        Authentication.onSessionInactive = (code) => {
            if (code === Authentication.USER_LOGGED_OUT) {
                console.log("Signed out. Goodbye.");
                this.setState({...this.state, logoutOccurred: true});
            } else if (code === Authentication.SESSION_INVALIDATED) {
                console.log("Session ended.");
                this.setState({...this.state, sessionEnded: true });
            } else {
                console.log("Unhandled session event code: " + code);
            }
        };

        Authentication.onSessionStarted = Authentication.onSessionContinued = () => {
            console.log("Session started.");
            this.setState({...this.state, sessionEnded: false });
        };

        //console.log("User logged in: " + Authentication.getUserName());

        try{
            this.getData();
        }catch(error){
            console.error(error);
        }
    }

    getData = async () => {
        this.setState({...this.state, isLoadingData: true});
        const fadeInWaitApologyTimeout = setTimeout(() => {
            this.setState({fadeInWaitApology: true})
        }, 2000); //2 seconds

        const loadDataPromise = this.loadData();
        let response = undefined;
        try{
            response = await loadDataPromise;
        }catch(error){
            console.error(error);
        }

        let responseData = [];
        if (response) {
            responseData = response.data;
        }

        const loadDocumentationPromise = fetchDocumentationEntities();
        await loadDocumentationPromise;

        clearTimeout(fadeInWaitApologyTimeout);
        this.setState({...this.state, isLoadingData: false, fadeInWaitApology:false, data: responseData});
    };

    loadData = async () => {
        try {
            const response = await fetch(Endpoints.data, {
                method: "GET",
                credentials: "include"
            });

            if (!response.ok){
                this.handleAuthenticationError(response);
                return undefined;
            }

            return await response.json();
        }catch(error){
            this.handleConnectionError(error);
            return undefined;
        }
    };

    handleAuthenticationError = (response) => {
        console.log(response);
    };

    handleConnectionError = (error) => {
        console.error(error);
    };

    selectMainMenuItem(item) {
        this.setState({...this.state, activeMainMenuItem: item });
    }

    componentWillUnmount() {
        Authentication.onSessionInactive = undefined;
        Authentication.onSessionStarted = undefined;
    }

    render() {
        if (this.state.logoutOccurred) {
            return <Redirect to={{
                pathname: '/login',
                state: { from: this.props.location }
            }} />;
        }

        const { match } = this.props;

        const selectMainMenuItemCallback = item => this.selectMainMenuItem(item);

        return (
            <div>
                <MainMenu activeItem={this.state.activeMainMenuItem} />
                {
                    this.state.isLoadingData?
                    (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className={"d-flex align-items-center"}>
                                <Spinner animation="grow" style={{width: "4rem", height: "4rem"}}>
                                    <span className="sr-only">Loading Data</span>
                                </Spinner>
                                <h2 style={{ paddingLeft: '5px' }}><strong>Loading Data</strong></h2>
                            </div>

                            <div>
                                <Fade in={this.state.fadeInWaitApology} appear={true} timeout={3000}>
                                    <h5>Please wait. This may take a while.</h5>
                                </Fade>
                            </div>

                        </div>
                    ):(
                        <Switch>
                            <Route path={`${match.url}docs`} render={props => <SeaStarDocumentation {...props} selectMainMenuItem={selectMainMenuItemCallback} />} />
                            <Route path={`${match.url}myseastarpds`} render={props => <SeaStarPdOverview {...props} data={this.state.data} selectMainMenuItem={selectMainMenuItemCallback} />} />
                            <Route path={`${match.url}myseastardomains`} render={props => <SeaStarDomains {...props} data={this.state.data} selectMainMenuItem={selectMainMenuItemCallback} />} />
                            <Route path={`${match.url}myseastarawsaccounts`} render={props => <SeaStarAwsAccounts {...props} data={this.state.data} selectMainMenuItem={selectMainMenuItemCallback} />} />
                            <Route path={`${match.url}pddetails/:pdId`} render={props => <SeaStarPdDetails {...props} data={this.state.data} selectMainMenuItem={selectMainMenuItemCallback} />} />

                            {/* Default entry if no page was given (e.g. user logged in or opened a generic link to start page) */}
                            <Route path="/" exact={true} render={() => <Redirect to={{ pathname: '/myseastarpds' }} />} />

                            {/* Not Found, i.e. there is a non-empty path but it does not refer to a content class */}
                            <Route render={props => <NotFoundPage {...props} selectMainMenuItem={selectMainMenuItemCallback} />} />
                        </Switch>
                    )
                }
            </div>);
    }
}

const Root = withRouter(RootUnboundToRouter);
const App = () => (
    <div>
        <Router>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/" component={Root} />
            </Switch>
        </Router>
    </div>
);

export default App;
