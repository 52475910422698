import Endpoints from "./Endpoints";

const DOCUMENTATION_ENTITIES = {};

export async function fetchDocumentationEntities() {
	try {
		const response = await fetch(Endpoints.fetchDocumentationEntities, {
			method: "GET",
			credentials: "include"
		});

		if (!response.ok) {
			console.error("Failed to fetch documentation entities (response status: " + response.status + ")");
			clearDocumentationEntities();
		} else {
			clearDocumentationEntities();
			let data = await response.json();
			for (const entityName in data.entities) {
				if (data.entities.hasOwnProperty(entityName)){
					DOCUMENTATION_ENTITIES[entityName] = data.entities[entityName];
				}
			}
		}
	} catch (error) {
		console.error(error);
		clearDocumentationEntities();
	}
}

export function transformFileUri(uri) {
	if (uri){
		const uriProtocolsPattern = new RegExp('^(?:[a-z]+:)?//', 'i');
		if (!uri.startsWith("#") && !uriProtocolsPattern.test(uri)) {
			return Endpoints.fetchDocumentationFile + uri;
		}
	}
	return uri;
}

export function getDocumentationEntity(entityName) {
	if (DOCUMENTATION_ENTITIES[entityName] === undefined) {
		console.warn("Entity '" + entityName + "' not defined");
		return null;
	} else {
		return DOCUMENTATION_ENTITIES[entityName];
	}
}

function clearDocumentationEntities() {
	for (let member in DOCUMENTATION_ENTITIES) {
		delete DOCUMENTATION_ENTITIES[member];
	}
}
