import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { tomorrowNight, defaultStyle } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { transformFileUri } from './DocumentationEntities'
import { isEqual } from 'lodash'
import Sidebar from './Sidebar'

/* SYNTAX HIGLIGHTING */
class CodeBlock extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string,
  }

  static defaultProps = {
    language: null,
  }

  render() {
    const { language, value } = this.props
    const style = (language === 'text' || !language) ? defaultStyle : tomorrowNight

    return (
      <SyntaxHighlighter style={style} language={language}>
        {value}
      </SyntaxHighlighter>
    )
  }
}

/* END SYNTAX HIGLIGHTING */

class DocumentationViewer extends React.Component {

  constructor(props) {
    super(props)

    this.headingDeduplicationMap = new Map()
    this.headings = []
    this.state = {toc: undefined}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setState({ toc: this.getTocTree(this.headings) })
    if (this.props.anchor){
      this.scrollToElementWithAnchor(this.props.anchor);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const documentationEntityChanged = this.props.documentationEntity !== nextProps.documentationEntity
    // deep equal comparison
    const tocChanged = !isEqual(this.state.toc, nextState.toc)
    return documentationEntityChanged || tocChanged
  }

  scrollToElementWithAnchor(anchor) {
    const element =  document.getElementById(anchor.replace("#",""));
    if (element){
      element.scrollIntoView();
    }
  }

  renderImage(image) {
    return <img src={image.src} alt={image.alt} title={image.title} style={{ maxWidth: '100%' }}/>
  }

  /* BEGIN: Fix for link targets in TOC not working as the headers define no anchors
   * Stolen from: https://github.com/rexxars/react-markdown/issues/69
   */
  flattenHeadings = (text, child) => {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(this.flattenHeadings, text)
  }

  renderHeadings = (heading) => {
    const children = React.Children.toArray(heading.children)
    const text = children.reduce(this.flattenHeadings, '')
    // Fixing fix: "A / B" is linked by TOC as "A--B", where the original code would lead to "A---B".
    // Remove '/' characters as a hot fix.
    let slug = text.replace(/\//, '').toLowerCase().replace(/\W/g, '-')

    // make heading ids unique
    if (this.headingDeduplicationMap) {
      if (this.headingDeduplicationMap.has(slug)) {
        const nextNumber = this.headingDeduplicationMap.get(slug) + 1;
        this.headingDeduplicationMap.set(slug, nextNumber)
        slug = slug + '-' + nextNumber;
      } else {
        this.headingDeduplicationMap.set(slug, 0)
      }
    }

    this.headings.push({ level: heading.level, id: slug, label: text })

    return React.createElement('h' + heading.level, { id: slug }, heading.children)
  }

  /* END fix */

  /*
    Stolen from https://stackoverflow.com/questions/44517560/generate-tree-from-flat-array-javascript
    Adapted to handle:
      - skipped levels in the tree
      - starting levels higher than the min level of 1
   */
  getTocTree = (array) => {
    const minLevel = 1
    const levels = [{}]
    let smallestLevelEncountered = Number.MAX_SAFE_INTEGER

    array.forEach(function (a) {
      if (a.level < smallestLevelEncountered) {
        smallestLevelEncountered = a.level
      }

      let actualLevel = (a.level <= levels.length) ? a.level : levels.length

      if (a.level <= smallestLevelEncountered) {
        actualLevel = minLevel
      }

      levels.length = actualLevel
      levels[actualLevel - 1].items = levels[actualLevel - 1].items || []
      a.href = '#' + a.id
      a.visible = true
      levels[actualLevel - 1].items.push(a)
      levels[actualLevel] = a
    })
    return levels[0].items
  }

  flattenNodeChildren = (text, child) => {
    return (child.type === 'text')
      ? text + child.value
      : child.children.reduce(this.flattenNodeChildren, text)
  }

  hideTableOfContentsHeading = (node) => {
    if (node.type === 'heading') {
      const tocRegExp = new RegExp('^(headings|table[ -]of[ -]contents?)$', 'i');
      const text = node.children.reduce(this.flattenNodeChildren, "");
      return !tocRegExp.test(text);
    } else {
      return true
    }
  }

  render() {
    this.headingDeduplicationMap = new Map()
    this.headings = []
    this.setState({ toc: [] })

    return (
      (this.props.documentationEntity && typeof this.props.documentationEntity === 'string' && this.props.documentationEntity.length > 0) ? (
        <div className="d-flex flex-row justify-content-end" id={"documentationviewer"}>
          <div className={"pb-4"} style={{ width: '80%', minWidth:"80%", maxWidth:"80%"}}>
            <ReactMarkdown
              source={this.props.documentationEntity}
              allowNode={this.hideTableOfContentsHeading}
              renderers={{
                heading: this.renderHeadings,
                image: this.renderImage,
                code: CodeBlock
              }}
              transformImageUri={transformFileUri}
              transformLinkUri={transformFileUri}
              plugins={[gfm]}
            />
          </div>
          <div className={"sticky-top toc-mainmenu-offset pl-2 pb-4"} style={{ zIndex: "998", width: '20%', minWidth:"20%", maxWidth:"20%", overflowY: 'auto'}}>
            <Sidebar
              variant={"toc"}
              items={this.state.toc}
              expanded={true}
              collapsible={false}/>
          </div>
        </div>
      ) : (
        <div>Sorry I could not find what you where looking for.</div>
      )
    )
  }
}

export default DocumentationViewer