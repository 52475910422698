export const base = "https://data.dashboard.seastar.pixida.com";

const Endpoints = {
    data: base + "/frontend/v1/handler?action=data",
    fetchDocumentationEntities: base + "/frontend/v1/handler?action=fetchDocumentationEntities",
    fetchDocumentationFile: base + "/frontend/v1/handler?action=fetchDocumentationFile&resourcePath=",
    enableGitlabForPd: base + "/frontend/v1/handler?action=enableGitlabForPd&pdId=",
    setGitlabConfigForPd: base + "/frontend/v1/handler?action=setGitlabConfigForPd&pdId="
};

export default Endpoints;