import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Fragment} from "react";
import * as React from "react";
import {Button, Card, Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import {withRouter} from "react-router-dom";
import InfoBox from "./InfoBox";
import MailHandler from "./MailHandler";

class SeaStarPdAwsAccounts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	};

	componentDidMount = async () => {
	};

	formatAccountLoginCell = (cellContent, row) => {
		const awsFederatedLoginUri = "https://account.activedirectory.windowsazure.com/applications/redirecttofederatedapplication.aspx";

		const urlParameter = new URLSearchParams({
			'Operation': 'SignIn',
			'applicationId': row.azureAdApplicationId,
			'ApplicationConstName': 'aws',
			'SingleSignOnType': 'Federated',
			'ApplicationDisplayName': row.name,
			'tenantId': "905ff8d4-7ea7-4317-a805-e9e90ef57135"
		});

		return (
			<div>
				<OverlayTrigger
					key={"awsAccountLoginButtonTooltip"}
					trigger={"hover"}
					placement="bottom"
					overlay={<Tooltip id={"awsAccountLoginButtonTooltip-bottom"}>{(this.props.currentUserIsMemberOrOwner) ? "Login to AWS Account" : "You are not part of the Team and cannot login"}</Tooltip>}
				>
					<span className="d-inline-block">
						<Button
							className="btn-sm"
							variant="dark"
							text="light"
							disabled={!this.props.currentUserIsMemberOrOwner}
							style={(this.props.currentUserIsMemberOrOwner) ? {} : { pointerEvents: 'none' }}
							onClick={() => {
								window.open(awsFederatedLoginUri + "?" + urlParameter.toString());
							}}
						><FontAwesomeIcon size="lg" icon={["fab","aws"]}/> Login</Button>
					</span>
				</OverlayTrigger>
			</div>);
	};

	formatBudgetWarnLevelHeader = (column, colIndex, { sortElement } ) => {
		return (
			<Fragment>
				{ column.text } &nbsp;
				<InfoBox
					key={"budgetInfoTooltip"}
					trigger={"click"}
					placement={"bottom"}
					title={column.text}
					documentationEntity="DescriptionOfBudgetsOfAwsAccounts" />
				{ sortElement }
			</Fragment>
		)
	};

	formatBudgetWarnLevels = (cellContent, row) => {
		return (<Fragment>
			<Container fluid={true}>
				<Row>
					<Col>
						<strong>Remind:</strong> ${row.budgetRemindDollar}
					</Col>
					<Col>
						<strong>Warn:</strong> ${row.budgetWarnDollar}
					</Col>
					<Col>
						<strong>Escalate:</strong> ${row.budgetEscalateDollar}
					</Col>
				</Row>
				<Row>
					<Col>
						<Button variant="outline-dark" style={{ width:'100%' }} className="btn-sm" onClick={(event) => {
								event.stopPropagation();
								MailHandler.mailtoItToRequestAwsAccountBudgetUpdate(row.awsAccountId)
							}}>
							<FontAwesomeIcon icon={"envelope"}/> Request Update
						</Button>
					</Col>
				</Row>
			</Container>
		</Fragment>);
	};

	onNoDataLoaded = () => {
		return (<div>No AWS Accounts for this PD</div>)
	};

	render(){
		const headerStyle = {verticalAlign: "top"};
		const columnStyle = {verticalAlign: "middle"};

		const columns = [{
			id: "awsAccountName",
			text: 'AWS Account Name',
			dataField: "name",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			style: columnStyle
		}, {
			id: "awsAccountId",
			text: "AWS Account ID",
			dataField: "awsAccountId",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			style: columnStyle
		},{
			id: "budgetWarnLevels",
			text: "Budget Warn Levels",
			dataField: "budgetRemindDollar",
			headerFormatter: this.formatBudgetWarnLevelHeader,
			formatter: this.formatBudgetWarnLevels,
			sort: false,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			style: columnStyle
		},{
			id: "awsAccountLogin",
			text: 'Login',
			dataField: "azureAdApplicationId",
			formatter: this.formatAccountLoginCell,
			sort: false,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			style: columnStyle
		}];

		const data = (this.props.pdAwsAccounts) ? this.props.pdAwsAccounts : [];

		return (
			<Container fluid={true}>
				<Card style={{border: "none"}}>
					<Card.Body>
						<Card.Title>
							<div className={"d-flex align-items-center"}>
								<div className={"d-flex flex-grow-1 align-items-center"}>
								</div>
								<div>
									<Button variant="outline-dark" onClick={(event) => {
										event.stopPropagation();
										MailHandler.mailtoItToRequestAwsAccount(this.props.pdManager, this.props.pdName)
									}}><FontAwesomeIcon icon={"envelope"}/> Request new AWS Account</Button>
								</div>
							</div>
						</Card.Title>
							<BootstrapTable
								bootstrap4
								keyField='azureAdApplicationId'
								data={ data }
								columns={ columns }
								noDataIndication={ this.onNoDataLoaded }
								defaultSorted={[{
									dataField: 'name',
									order: 'asc'
								}]}
							/>
					</Card.Body>
				</Card>
			</Container>
		)
	}
}

export default withRouter(SeaStarPdAwsAccounts);