import React, {Fragment} from 'react';
import { Redirect } from 'react-router-dom';

import Authentication from './Authentication';

import {
	Container, Row, Col, Card, Button, Spinner
} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faSignInAlt);

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loginInitialized: false,
			redirectToReferrer: false,
			loginErrorMsg: '',
			redirectTo: undefined
		}
	}

	componentDidMount = () => {
		this.wasLoginInitialized(this.props.location);

		Authentication.onLoginInitialized = () => {
			this.setState({loginInitialized: true, redirectToReferrer: false, loginErrorMsg: ''})
		};

		Authentication.onAuthenticationFailed = (code) => {
			let msg;
			if (code === Authentication.UNKNOWN_SERVER_ERROR) {
				msg = "The server was unable to process your request. Please try again later.";
			} else if (code === Authentication.CONNECTION_ERROR) {
				msg = "Please check your internet connection.";
			} else if (code === Authentication.REJECTED) {
				msg = "Please check your credentials or try again later.";
			} else {
				msg = "Unknown error.";
			}
			this.setState({ loginInitialized: false, redirectToReferrer: false, loginErrorMsg: msg});
		};

		const leaveLoginPageCallback = (fromPathname) => this.setState({ loginInitialized: false, redirectToReferrer: true, loginErrorMsg: '', redirectTo: fromPathname });
		Authentication.onSessionStarted = leaveLoginPageCallback;
		Authentication.onSessionContinued = leaveLoginPageCallback;

		// used when accessing the dashboard from myapps.microsoft.com
		if (document.referrer) {
			const referrer = new URL(document.referrer);

			if (!this.state.loginInitialized &&
				referrer.origin === "https://account.activedirectory.windowsazure.com" &&
				referrer.searchParams.get("Operation") === "SignIn" &&
				referrer.searchParams.get("applicationId") === "d46d9539-7a44-4a7f-aa13-dd3c3c7aac8f" &&
				referrer.searchParams.get("ApplicationDisplayName") === "Sea Star Dashboard") {
				Authentication.login();
			}
		}
	};

	wasLoginInitialized = (location) => {
		if (location && location.state && location.state.from){
			const parsedQueryString = Authentication.getParsedQueryString(location.state.from);
			if (parsedQueryString && parsedQueryString.code){
				this.setState({loginInitialized: true})
			}
		}
	};

	render() {
		if (this.state.redirectToReferrer) {
			const from = {pathname: '/'};
			if (this.state.redirectTo){
				from.pathname = this.state.redirectTo;
			}
			console.log("Login complete. Redirecting to: " + from.pathname);
			return (
				<Redirect to={from}/>
			);
		}

		let fromParam = {pathname: '/'};
		if (this.props && this.props.location && this.props.location.state && this.props.location.state.from){
			fromParam = this.props.location.state.from;
		}

		return (
			<Container>
				<Row style={{paddingTop: 50}}>
					<Col lg={"3"}/>
					<Col lg={"6"}>
						<Card bg="dark" text="white" className="text-center">
							<Card.Header as={"h1"}>
								<Card.Img style={{width:"20vh"}} variant="top" src={process.env.PUBLIC_URL + '/img/PIXIDA_Logo_White_Red_RGB.png'} />
								<p>SeaStar Dashboard</p>
							</Card.Header>
							<Card.Body>
								{this.state.loginInitialized ? (
									<Spinner animation="grow" role="status">
										<span className="sr-only">Login in...</span>
									</Spinner>
								) : (
									<Fragment>
										<div style={{marginBottom:"1vh"}}>Join the <strong>Sea Star Users</strong> Teams team to gain access to the Sea Star Dashboard.</div>
										<div><Button variant="light" text="black" onClick={() => {Authentication.login(fromParam)}}><FontAwesomeIcon icon="sign-in-alt"/> SSO Login</Button></div>
									</Fragment>
								)}
							</Card.Body>
						</Card>
					</Col>
					<Col lg={"3"}/>
				</Row>
			</Container>
		)
	}
}

export default Login;