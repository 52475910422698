import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {Button, ButtonGroup, Container, Dropdown, Card} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

import {withRouter} from "react-router-dom";
import InfoBox from "./InfoBox";
import MailHandler from "./MailHandler";

class SeaStarPdUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	};

	componentDidMount = async () => {};

	createTeamList = () => {
		if (this.props.pdManager && this.props.pdOwner && this.props.pdMember){
			return [...this.props.pdManager, ...this.props.pdOwner, ...this.props.pdMember].filter((user, index, array) =>
				index === array.findIndex((u) => (
					u.objectId === user.objectId
				))
			);
		}else {
			return [];
		}
	};

	onNoDataLoaded = (role) => {
		return (<div className={"text-danger"}><FontAwesomeIcon icon={"exclamation-triangle"}/> No {role} for this PD</div>)
	};

	formatUserContactCell = (cellContent, row) => {
		return (
			<div>
				<Button variant="outline-dark" className="btn-sm" onClick={(event) => {
					event.stopPropagation();
					MailHandler.contactPdUsers([row])
				}}><FontAwesomeIcon icon={"envelope"}/> Contact</Button>
			</div>);
	};

	render(){
		const dataManager = (this.props.pdManager) ? this.props.pdManager : [];
		const dataOwner = (this.props.pdOwner) ? this.props.pdOwner : [];
		const dataMember = (this.props.pdMember) ? this.props.pdMember : [];
		const dataTeam = this.createTeamList();

		const headerStyle = {verticalAlign: "top"};
		const columnStyle = {verticalAlign: "middle"};

		const columns = [{
			id: "userName",
			text: 'Name',
			dataField: "displayName",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			style: columnStyle
		},{
			id: "userEmailAddress",
			text: 'E-Mail Address',
			dataField: "emailAddress",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			style: columnStyle
		},{
			id: "userContactbutton",
			text: 'Contact',
			dataField: "",
			formatter: this.formatUserContactCell,
			sort: false,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			style: columnStyle
		}];

		return (
			<Container fluid={true}>
				<Card style={{border: "none"}}>
					<Card.Body>
						<Card.Title>
							<div className={"d-flex align-items-center"}>
								<div className={"d-flex flex-grow-1 align-items-center"}>
									<div className={"pr-2"}>PD Manager</div>
									<InfoBox
										key={"pdManagerRoleTooltip"}
										trigger={"click"}
										placement={"right"}
										title={"PD Manager"}
										documentationEntity="DescriptionOfPdManagerRole"/>
								</div>
								<div>
									<Dropdown as={ButtonGroup}>
										<Button variant="outline-dark" onClick={(event) => {
											event.stopPropagation();
											MailHandler.contactPdUsers(dataTeam);
										}}><FontAwesomeIcon icon={"envelope"}/> Contact Team</Button>

										<Dropdown.Toggle split variant="outline-dark" id="dropdown-split-basic" />

										<Dropdown.Menu>
											<Dropdown.Item onClick={(event) => {
												event.stopPropagation();
												MailHandler.contactPdUsers(dataManager);
											}}>Contact PD Manager</Dropdown.Item>
											<Dropdown.Item onClick={(event) => {
												event.stopPropagation();
												MailHandler.contactPdUsers(dataOwner);
											}}>Contact PD Data Owner</Dropdown.Item>
											<Dropdown.Item onClick={(event) => {
												event.stopPropagation();
												MailHandler.contactPdUsers(dataMember);
											}}>Contact PD Member</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</Card.Title>
							<BootstrapTable
								bootstrap4
								keyField='objectId'
								data={ dataManager }
								columns={ columns }
								noDataIndication={ () => {return this.onNoDataLoaded("PD Manager")} }
								defaultSorted={[{
									dataField: 'displayName',
									order: 'asc'
								}]}
							/>
					</Card.Body>
				</Card>
				<Card style={{border: "none"}}>
					<Card.Body>
						<Card.Title>
							<div className={"d-flex align-items-center"}>
								<div className={"pr-2"}>PD Data Owner(s) <span style={{ fontSize: '60%' }}>(change by nominating owner(s) of the PD's Microsoft Teams team{this.props.teamName ? " \"" + this.props.teamName + "\"" : ""})</span></div>
								<InfoBox
									key={"pdDataOwnerRoleTooltip"}
									trigger={"click"}
									placement={"right"}
									title={"Data Owner"}
									documentationEntity="DescriptionOfPdDataOwnerRole"/>
							</div>
						</Card.Title>
							<BootstrapTable
								bootstrap4
								keyField='objectId'
								data={ dataOwner }
								columns={ columns }
								noDataIndication={ () => {return this.onNoDataLoaded("Data Owner")} }
								defaultSorted={[{
									dataField: 'displayName',
									order: 'asc'
								}]}
							/>
					</Card.Body>
				</Card>
				<Card style={{border: "none"}}>
					<Card.Body>
						<Card.Title>
							<div className={"d-flex align-items-center"}>
								<div className={"pr-2"}>PD Member(s) <span style={{ fontSize: '60%' }}>(change by adding/removing member(s) to/from the PD's Microsoft Teams team{this.props.teamName ? " \"" + this.props.teamName + "\"" : ""})</span></div>
								<InfoBox
									key={"pdMemberRoleTooltip"}
									trigger={"click"}
									placement={"right"}
									title={"Member"}
									documentationEntity="DescriptionOfPdMemberRole"/>
							</div>
						</Card.Title>
							<BootstrapTable
								bootstrap4
								keyField='objectId'
								data={ dataMember }
								columns={ columns }
								noDataIndication={ () => {return this.onNoDataLoaded("Member")} }
								defaultSorted={[{
									dataField: 'displayName',
									order: 'asc'
								}]}
							/>
					</Card.Body>
				</Card>
			</Container>
		)
	}
}

export default withRouter(SeaStarPdUsers);