import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Fragment} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";

class ComingSoon extends React.Component{
	render(){
		return (
			<Container>
				<Row style={{paddingTop: 50}}>
					<Col lg={"2"}/>
					<Col lg={"8"}>
						<Card className="text-center">
							<Card.Header as={"h1"}>
								Coming Soon<sup>&trade;</sup>
							</Card.Header>
							<Card.Body>
								{(this.props.backButton) ? <Fragment>For now, maybe you want to go <Button variant={"dark"} onClick={this.props.history.goBack}><FontAwesomeIcon icon="arrow-left" /> Back</Button> ?</Fragment> : <Fragment/>}
							</Card.Body>
						</Card>
					</Col>
					<Col lg={"2"}/>
				</Row>
				<br/>
			</Container>
		)
	}
}

export default withRouter(ComingSoon);