import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button, Card, Container, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Endpoints from './Endpoints'
import InfoBox from './InfoBox'
import SeaStarPdGitlabOwners from './SeaStarPdGitlabOwners'

class SeaStarPdGitlab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasGitlabConfig: this.props.pdGitlabConfig !== undefined,
      gitlabEnableCommandStarted: false,
      gitlabEnableCommandSuccess: false,
      enableGitlabCommandErrorResult: undefined
    }
  };

  async enableGitlab() {
    const pdId = this.props.pdId
    this.setState({ gitlabEnableCommandStarted: true})

    this.sendEnableGitlabCommand(pdId).then(response => {
      if (response.error) {
        // Error flag set
        this.setState({ gitlabEnableCommandStarted: false, gitlabEnableCommandSuccess: false, enableGitlabCommandErrorResult: response.error })
      } else {
        this.setState({ gitlabEnableCommandStarted: false, gitlabEnableCommandSuccess: true, enableGitlabCommandErrorResult: undefined })
      }
    })
  }

  sendEnableGitlabCommand = async (pdId) => {
    try {
      const response = await fetch(Endpoints.enableGitlabForPd + pdId, {
        method: 'POST',
        credentials: 'include'
      })

      if (!response.ok) {
        if (response.status === 403) {
          return { error: 'Backend returned that you have no permissions to create a gitlab group for this PD.' }
        }
        if (response.status === 500) {
          return { error: 'Internal Error. Please report to SeaStar Admins.' }
        } else {
          return { error: 'Server did not confirm request. Response is: ' + response.status }
        }
      }

      return await response.json()
    } catch (error) {
      console.error(error)
      return { error: 'Server did not confirm request. Error is: ' + error }
    }
  }

  render() {
    let successResult = ''

    // TODO backend should return whether gitlab group is still in creation or not or if there was an error during creation
    if (this.state.gitlabEnableCommandSuccess) {
      successResult = <div>
        <span style={{ fontWeight: 'bold' }}>Your gitlab group was just requested.</span> Please wait
        up to an hour for the provisioning to complete. If you don't see any
        Gitlab group for this PD afterwards, something is definitely
        wrong and you may ask for support.
        <span style={{ fontWeight: 'bold' }}> You can refresh this page manually if you want your new gitlab resource to be shown.</span>
      </div>
    }

    let errorResult = ''
    if (this.state.enableGitlabCommandErrorResult !== undefined) {
      errorResult = <span style={{ fontWeight: 'bold' }}>An error occurred while creating your gitlab
        group: {this.state.enableGitlabCommandErrorResult}</span>
    }

    let requestGitlabGroupButton
    if (this.state.hasGitlabConfig) {
      requestGitlabGroupButton = <div className={'d-flex align-items-center'}>
          <div>Gitlab is enabled for this project.</div>
          <div>Gitlab Group URL: <a target="_blank" rel="noopener noreferrer"
                                  href={this.props.pdGitlabConfig.gitlabGroupUrl}>{this.props.pdGitlabConfig.gitlabGroupUrl}</a>
          <div style={{ fontWeight: 'bold' }}> You have to login once to Gitlab before you will be granted access to the Gitlab group. It can take about 20 minutes after your first login for the access to the group to be granted.</div>
        </div>
      </div>
    } else {
      if (this.state.gitlabEnableCommandStarted){
        requestGitlabGroupButton = <div>
          <Spinner animation="grow" role="status">
            <span className="sr-only">Waiting for Gitlab group creation...</span>
          </Spinner>
        </div>
      }else {
        requestGitlabGroupButton = <div>
          <OverlayTrigger
            key={"gitlabRequestGroupButton"}
            trigger={"hover"}
            placement="top"
            overlay={<Tooltip id={"gitlabRequestGroupButtonTooltip-top"}>{(this.props.currentUserIsMember || this.props.currentUserIsOwner) ? "Request Gitlab Group" : "You are not part of the Team and cannot request a Gitlab Group"}</Tooltip>}
          >
					<span className="d-inline-block">
						<Button
              className="btn-sm"
              variant="dark"
              text="light"
              disabled={!(this.props.currentUserIsMember || this.props.currentUserIsOwner)}
              style={(this.props.currentUserIsMember || this.props.currentUserIsOwner) ? {} : { pointerEvents: 'none' }}
              onClick={(event) => {
                event.stopPropagation()
                if (window.confirm('Do you really want to apply for a gitlab group?\n'
                  + 'You cannot revert this operation, so please create the resource only if you need it.')) {
                  this.enableGitlab()
                }
              }}><FontAwesomeIcon icon="plus"/> Enable Gitlab for this PD</Button>
					</span>
          </OverlayTrigger>
        </div>
      }
    }

    return (
      <Container fluid={true}>
        <Card style={{ border: 'none' }}>
          <Card.Body>
            <Card.Title>
              <div className={'d-flex align-items-center'}>
                <div className={'d-flex flex-grow-1 align-items-center'}>
                  {requestGitlabGroupButton}
                </div>
              </div>
            </Card.Title>
            <div>
              {successResult}
              {errorResult}
            </div>
          </Card.Body>
        </Card>
        {!this.state.hasGitlabConfig || <Card style={{ border: 'none' }}>
          <Card.Body>
            <Card.Title>
              <div className={'d-flex align-items-center'}>
                <div className={'pr-2'}>Gitlab Data Owners</div>
                <InfoBox
                  key={'gitlabDataOwnerRoleTooltip'}
                  trigger={'click'}
                  placement={'right'}
                  title={'Gitlab Data Owner'}
                  documentationEntity="DescriptionOfGitlabDataOwnerRole"/>
              </div>
            </Card.Title>
            <SeaStarPdGitlabOwners
              pdGitlabConfig={this.props.pdGitlabConfig}
              pdId={this.props.pdId}
              pdManager={this.props.pdManager} pdOwner={this.props.pdOwner} pdMember={this.props.pdMember}
              currentUserIsOwner={this.props.currentUserIsOwner}/>
          </Card.Body>
        </Card>}
      </Container>
    )
  }
}

export default withRouter(SeaStarPdGitlab)