import React from 'react';
import {
	Container,
	Row,
	Col,
	Card ,
	Button
} from 'react-bootstrap';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowLeft);


export class NotFoundPage extends React.Component {
	constructor(props) {
		super(props);
		this.props.selectMainMenuItem(false);
	}

	render() {
		return (
			<Container>
				<Row style={{paddingTop: 50}}>
					<Col lg={"2"}/>
					<Col lg={"8"}>
						<Card className="text-center">
							<Card.Header as={"h1"}>
								Not Found
							</Card.Header>
							<Card.Body>
								<Card.Body><span style={{ fontWeight: 'bold' }}>Sorry.</span> I don't know the requested page. Maybe you want to go <Button variant={"dark"} onClick={this.props.history.goBack}><FontAwesomeIcon icon="arrow-left" /> Back</Button> ?</Card.Body>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={"2"}/>
				</Row>
			</Container>
		)
	}
}
