import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Col, OverlayTrigger, Popover, Row, Tooltip} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import {withRouter} from "react-router-dom";
import {getDocumentationEntity, transformFileUri} from "./DocumentationEntities";

class OpenDocumentationButton extends React.Component {
	goToDocumentation = (event) => {
		event.stopPropagation();
		this.props.history.push("/docs/userHandbook");
	};

	render() {
		return (
			<OverlayTrigger
				key={"showDocumentationButton"}
				placement={"top"}
				overlay={
					<Tooltip id={`showDocumentationButtonTooltip`}>
						Go to C* Documentation for more Information
					</Tooltip>
				}
			>
				<FontAwesomeIcon icon={"book-reader"} style={{cursor: "pointer"}} onClick={
					this.goToDocumentation
				}/>
			</OverlayTrigger>
		)
	}
}

const OpenDocumentationButtonWithRouter = withRouter(OpenDocumentationButton);

function ImageRenderer(props) {
	return <img src={props.src} alt={props.alt} title={props.title} style={{maxWidth: '100%'}} />
}

class InfoBox extends React.Component {
	render() {
		const style = {
			maxWidth: "50%"
		};

		let content;
		if (this.props.documentationEntity !== undefined) {
			let markdownContent = getDocumentationEntity(this.props.documentationEntity);
			if (markdownContent === null) {
				markdownContent = "Documentation not available.";
			}
			content = <ReactMarkdown source={markdownContent} transformImageUri={transformFileUri} transformLinkUri={transformFileUri} renderers={{image: ImageRenderer}}/>;
		} else if (typeof this.props.content === "function") {
			content = this.props.content();
		} else {
			content = this.props.content;
		}

		return (
			<OverlayTrigger
				trigger={this.props.trigger}
				rootClose={true}
				key={this.props.key}
				placement={this.props.placement}
				overlay={
					<Popover id={this.props.key + "-" + this.props.placement} style={(this.props.style)? this.props.style : style}>
						<Popover.Title as="h3" >
							<Row>
								<Col xs={"auto"} md={"auto"} lg={"auto"}>
									{(typeof this.props.title === "function") ? this.props.title() : this.props.title}
								</Col>
								<Col className={"ml-auto"} xs={2} md={2} lg={2} style={{marginRight:"4%"}}>
									<OpenDocumentationButtonWithRouter/>
								</Col>
							</Row>
						</Popover.Title>
						<Popover.Content>
							{content}
						</Popover.Content>
					</Popover>
				}>
				<FontAwesomeIcon onClick={(event) => {event.stopPropagation();}} icon={"info-circle"} style={{cursor: "pointer"}}/>
			</OverlayTrigger>
		);
	}
 }

 export default withRouter(InfoBox);