import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import 'moment/min/locales'
import React, { Fragment } from 'react'
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter'

import Authentication from './Authentication'
import InfoBox from './InfoBox'
import MailHandler from './MailHandler'
import './react-bootstrap-table-overwrites.css'

class UserList extends React.Component{
	render() {
		return (
			<div>
				{this.props.users.map(user => <Fragment key={user.objectId}>{user.displayName}<br/></Fragment>)}
			</div>
		)
	}
}

export class SeaStarPdOverview extends React.Component {
	constructor(props) {
		super(props);
		props.selectMainMenuItem('myseastarpds');
		this.state = {
			data:props.data,
			locale: window.navigator.language
		};
	};

	componentDidMount = async () => {
	};

	determineRolesOfLoggedInUser = (userObjectId, userRoles) => {
		const myRoles = [];
		if (userRoles.manager.some(manager => manager.objectId === userObjectId)){
			myRoles.push("PD Manager");
		}
		if (userRoles.owner.some(owner => owner.objectId === userObjectId)){
			myRoles.push("PD Data Owner");
		}
		if (myRoles.length === 0){
			if (userRoles["member"].some(member => member.objectId === userObjectId)){
				myRoles.push("PD Member");
			}
		}
		return myRoles;
	};

	currentUserIsManagerOfPd = (pdData) => {
		const currentUserObjectId = Authentication.getUserObjectId();
		if (pdData.userRoles && Array.isArray(pdData.userRoles.manager)) {
			return pdData.userRoles.manager.some(manager => manager.objectId === currentUserObjectId);
		}
		return false;
	};

	currentUserIsOwnerOfPd = (pdData) => {
		const currentUserObjectId = Authentication.getUserObjectId();
		if (pdData.userRoles && Array.isArray(pdData.userRoles.owner)) {
			return pdData.userRoles.owner.some(owner => owner.objectId === currentUserObjectId);
		}
		return false;
	};

	currentUserIsMemberOfPd = (pdData) => {
		const currentUserObjectId = Authentication.getUserObjectId();
		if (pdData.userRoles && Array.isArray(pdData.userRoles.member)) {
			return pdData.userRoles.member.some(member => member.objectId === currentUserObjectId);
		}
		return false;
	};

	prepareDataForDisplay = (data) => {
		const preparedData = [];
		if (data && data.pds){
			for (let index = 0; index < data.pds.length; index++) {
				let datum = data.pds[index];
				let preparedDatum = {
					pdId: datum.pdId
				};

				if (datum.masterData !== undefined){
					let setupDateMoment = moment(datum.masterData.setupDateDDMMYYYY, "DDMMYYYY");
					if (setupDateMoment !== undefined){
						preparedDatum.setupDate = setupDateMoment.locale(this.state.locale).format("L"); //Localized Day-Month-Year display format
						preparedDatum.setupDateMsUtc = setupDateMoment.utc().valueOf();
					}else{
						preparedDatum.setupDate = undefined;
						preparedDatum.setupDateMsUtc = Number.MAX_SAFE_INTEGER;
					}
					preparedDatum.codeName = datum.masterData.codeName;
					preparedDatum.technicalReferenceName = datum.masterData.technicalReferenceName;
					preparedDatum.productName = datum.masterData.productName;
				}

				if (datum.team !== undefined){
					preparedDatum.microsoftTeamsTeamId = datum.team.teamId;
					preparedDatum.microsoftTeamsTeamDisplayName = datum.team.teamDisplayName;
					preparedDatum.microsoftTeamsTeamLastUpdateTimestampEpochMillis = datum.team.lastUpdateTimestampEpochMillis;
				}

				if (datum.awsAccounts !== undefined){
					preparedDatum.awsAccounts = datum.awsAccounts
				}else{
					preparedDatum.awsAccounts = [];
				}

				//userRoles may not be undefined. If they are, warnings will be displayed where appropiate, but the backend should already ignore PDs without userRoles
				if (datum.userRoles !== undefined){
					preparedDatum.userRolesManager = datum.userRoles.manager;
					preparedDatum.userRolesOwner = datum.userRoles.owner;
					preparedDatum.userRolesMember = datum.userRoles.member;
					const currentUserObjectId = Authentication.getUserObjectId();
					preparedDatum.myRoles = this.determineRolesOfLoggedInUser(currentUserObjectId, datum.userRoles);
					preparedDatum.currentUserIsManager = this.currentUserIsManagerOfPd(datum);
					preparedDatum.currentUserIsOwner = this.currentUserIsOwnerOfPd(datum);
					preparedDatum.currentUserIsMember = this.currentUserIsMemberOfPd(datum);
				}

				if(datum.domains !== undefined){
					preparedDatum.domains = datum.domains;
				}else{
					preparedDatum.domains = [];
				}

				if (datum.masterData.costCenter !== undefined) {
					preparedDatum.costCenter = datum.masterData.costCenter;
				} else {
					preparedDatum.costCenter = undefined;
				}

				preparedDatum.gitlabConfig = datum.gitlabConfig;

				preparedData.push(preparedDatum);
			}
		}
		return preparedData;
	};

	onNoDataLoaded = () => {
		return (<div>Currently, you don't have access to any product developments. To obtain access, you need to join a Microsoft Teams teams belonging to a products. It will then show up here automatically after a few minutes. For further information, get started reading the "User Handbook" in the documentation center.</div>)
	};

	formatUserCell = (cellContent) => {
		return (
			<div>
				{(cellContent !== undefined && cellContent.length > 0) ?
					<Fragment>
						<UserList users={cellContent}/>
						<Button className="btn-sm" style={{ marginTop: '4px' }} variant="outline-dark" onClick={(event) => {
							event.stopPropagation();
							MailHandler.contactPdUsers(cellContent);
						}}><FontAwesomeIcon icon={"envelope"}/> Contact All</Button>
					</Fragment>
					:
					<Fragment>
						<div className={"text-danger"}><FontAwesomeIcon icon={"exclamation-triangle"}/> No one defined for this role in this PD</div>
					</Fragment>}
			</div>);
	};

	render() {
		const rowStyle = { cursor: 'pointer' };
		const headerStyle = {verticalAlign: "top"};

		const roleFilterOptions = {
			"PD Manager": 'PD Manager',
			"PD Data Owner": 'PD Data Owner',
			"PD Member": 'PD Member'
		};

		let columns = [{
			id: "pdName",
			text: 'Product Name',
			dataField: "productName",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle
		}, {
			id: "msTeamsTeam",
			text: "Microsoft Teams Team",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			dataField: "microsoftTeamsTeamDisplayName",
			headerFormatter: (column, colIndex, { sortElement } ) => {
				return (
					<Fragment>
						<FontAwesomeIcon size="lg" icon={["fab","microsoft"]}/> {column.text} &nbsp;
						<InfoBox
							key={"pdMsTeamsTooltip"}
							trigger={"click"}
							placement={"bottom"}
							title={()=>{return (<Fragment>Microsoft Teams<sup>&trade;</sup> Team</Fragment>)}}
							documentationEntity="DescriptionOfTeamsTeams" />
						{ sortElement }
					</Fragment>
				);
			},
			formatter: (cellContent) => {
				return (
					<Fragment>
						{(cellContent !== undefined) ? cellContent : <div className={"text-danger"}><FontAwesomeIcon icon={"exclamation-triangle"}/> Microsoft Teams Team missing!</div> }
					</Fragment>);
			}
		},{
			id: "setupDate",
			text: 'Created At',
			dataField: "setupDate",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			sortValue: (cell, row) => row.setupDateMsUtc
		},{
			id: "myRoles",
			text: 'My Role(s)',
			dataField: "myRoles",
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			filter: selectFilter({
				options: roleFilterOptions,
				withoutEmptyOption: false,
				placeholder: "All roles"
			}),
			formatter: (cellContent) => {
				if (cellContent.length === 0) {
					return '-';
				} else {
					return cellContent.map((role) => <Fragment key={role + "key"}>{role} <br/></Fragment>)
				}
			}
		},{
			id: "pdManager",
			text: 'PD Manager',
			dataField: "userRolesManager",
			formatter: this.formatUserCell,
			sort: true,
			headerAlign: 'center',
			headerSortingStyle:{backgroundColor:"lightgray"},
			headerStyle: headerStyle,
			headerFormatter: (column, colIndex, { sortElement } ) => {
				return (
					<Fragment>
						{ column.text } &nbsp;
						<InfoBox
							key={"pdManagerTooltip"}
							trigger={"click"}
							placement={"bottom"}
							title={"PD Manager"}
							documentationEntity="DescriptionOfPdManagerRole"/>
						{ sortElement }
					</Fragment>
				);
			}
		},{
			id: "dataOwner",
			text: 'PD Data Owner',
			dataField: "userRolesOwner",
			formatter: this.formatUserCell,
			sort: true,
			headerAlign: 'center',
			headerSortingClasses : "bg-secondary text-light",
			headerStyle: headerStyle,
			headerFormatter: (column, colIndex, { sortElement } ) => {
				return (
					<Fragment>
						{ column.text } &nbsp;
						<InfoBox
							key={"pdDataOwnerTooltip"}
							trigger={"click"}
							placement={"bottom"}
							title={"PD Data Owner"}
							documentationEntity="DescriptionOfPdDataOwnerRole"/>
						{ sortElement }
					</Fragment>
				);
			}
		},{
			id: "hasAwsAccounts",
			text: "AWS Accounts",
			sort: true,
			headerAlign: 'center',
			headerSortingClasses : "bg-secondary text-light",
			headerStyle: headerStyle,
			dataField: "awsAccounts",
			headerFormatter: (column, colIndex, { sortElement } ) => {
				return (
					<Fragment>
						<FontAwesomeIcon size="lg" icon={["fab","aws"]}/> {column.text} &nbsp;
						{ sortElement }
					</Fragment>
				);
			},
			formatter: (cellContent, row) => {
				const numAwsAccounts = (cellContent !== undefined) ? cellContent.length : 0;
				return (
					<Fragment>
						{(numAwsAccounts > 0) ? <div>{numAwsAccounts} {(numAwsAccounts === 1) ? "AWS Account" : "AWS Accounts"}</div> : <div>-</div>}
						<Button variant="outline-dark" style={{ marginTop: '4px' }} className="btn-sm" onClick={(event) => {
							event.stopPropagation();
							MailHandler.mailtoItToRequestAwsAccount(row.userRolesManager, row.productName)
						}}><FontAwesomeIcon icon={"envelope"}/> Request</Button>
					</Fragment>);
			}
		},{
			id: "hasJiraProjects",
			text: "Jira Projects",
			sort: true,
			headerAlign: 'center',
			headerSortingClasses : "bg-secondary text-light",
			headerStyle: headerStyle,
			dataField: " ",
			headerFormatter: (column, colIndex, { sortElement } ) => {
				return (
					<Fragment>
						<FontAwesomeIcon size="lg" icon={["fab","jira"]}/> {column.text} &nbsp;
						{ sortElement }
					</Fragment>
				);
			},
			formatter: () => {
				return (
					<Fragment>Coming Soon<sup>&trade;</sup></Fragment>
				)
			}
		},{
			id: "hasGitlabGroups",
			text: "Gitlab Groups",
			sort: true,
			headerAlign: 'center',
			headerSortingClasses : "bg-secondary text-light",
			headerStyle: headerStyle,
			dataField: "gitlabConfig",
			headerFormatter: (column, colIndex, { sortElement } ) => {
				return (
					<Fragment>
						<FontAwesomeIcon size="lg" icon={["fab","gitlab"]}/> {column.text} &nbsp;
						{ sortElement }
					</Fragment>
				);
			},
			formatter: (cellContent, row) => {
				if (cellContent !== undefined) {
					return (<Fragment>
						<OverlayTrigger
							key={"gitlabGroupLinkButtonTooltip"}
							trigger={"hover"}
							placement="bottom"
							overlay={<Tooltip id={"gitlabGroupLinkButtonTooltip-bottom"}>{(row.currentUserIsOwner || row.currentUserIsMember) ? "Link to Gitlab" : "You are not part of the Team and cannot view the Gitlab Group"}</Tooltip>}
						>
					<span className="d-inline-block">
						<Button
							variant="outline-dark"
							disabled={!(row.currentUserIsOwner || row.currentUserIsMember)}
							style={(row.currentUserIsOwner || row.currentUserIsMember) ? {marginTop: '4px'} : { marginTop: '4px', pointerEvents: 'none' }}
							className="btn-sm"
							onClick={(event) => {
								event.stopPropagation();
								window.open(cellContent.gitlabGroupUrl, "_blank")
							}}
						><FontAwesomeIcon icon={["fab","gitlab"]}/> Open Gitlab Group</Button>
					</span>
						</OverlayTrigger>
					</Fragment>)
				} else {
					return (<Fragment>No Gitlab group yet</Fragment>)
				}
			}
		}];
		
		// Add cost center numbers for those who might need it
		let showCostCenterAsUserIsManagerOfSomePd = false;
		if (this.state.data && Array.isArray(this.state.data.pds)) {
			for (let i = 0; i < this.state.data.pds.length; i++) {
				const pdData = this.state.data.pds[i];

				showCostCenterAsUserIsManagerOfSomePd = this.currentUserIsManagerOfPd(pdData);
				if (showCostCenterAsUserIsManagerOfSomePd) {
					// We're done if we found one PD where the current user is manager of
					break;
				}
			}
		}
		let showCostCenterColumn = showCostCenterAsUserIsManagerOfSomePd
			|| Authentication.hasRole("it") || Authentication.hasRole("developer") || Authentication.hasRole("sales");
		if (showCostCenterColumn) {
			const costCenterColumnDefinition = {
				id: "costCenterColumnId",
				text: 'Cost Center',
				dataField: "costCenter",
				sort: true,
				headerAlign: 'center',
				headerSortingStyle: { backgroundColor: "lightgray" },
				headerStyle: headerStyle,
				formatter: (costCenterValue, row) => {
					const costCenterValueValid = costCenterValue !== undefined && costCenterValue.length > 0;
					const costCenterDisplayValue = costCenterValueValid ? costCenterValue : '-';
					if (row.currentUserIsManager) {
						return (<Fragment>
								<div>{costCenterDisplayValue}</div>
								<Button variant="outline-dark" style={{ marginTop: '4px' }} className="btn-sm" onClick={(event) => {
									event.stopPropagation();
									MailHandler.mailtoItToUpdateCostCenterOfPd(costCenterValueValid, costCenterValue, row.productName)
								}}><FontAwesomeIcon icon={"envelope"}/> Update</Button>
							</Fragment>);
					} else {
						return costCenterDisplayValue;
					}
				}
			};
			columns.unshift(costCenterColumnDefinition);
		}

		// add pdId for IT users
		let hasITRole = Authentication.hasRole("it");
		let hasDevRole = Authentication.hasRole("developer");
		let showIdColumn = hasITRole || hasDevRole;
		if (showIdColumn) {
			const pdIdColumn =
				{
					id: "pdId",
					text: 'Product Id',
					dataField: "pdId",
					sort: true,
					sortFunc: (leftPdIdString, rightPdIdString, order) => {
						if(leftPdIdString === undefined){
							return -1;
						}
						if(rightPdIdString === undefined){
							return 1;
						}

						let pdIdPrefix = "pd:";
						if(leftPdIdString.length >= pdIdPrefix.length && rightPdIdString.length >= pdIdPrefix.length){
							let leftId = leftPdIdString.slice(pdIdPrefix.length);
							let rightId = rightPdIdString.slice(pdIdPrefix.length);
							if (order === 'asc'){
								return leftId - rightId;
							} else{
								return rightId - leftId;
							}
						}

						return 0;
					},
					headerAlign: 'center',
					headerSortingStyle:{backgroundColor:"lightgray"},
					headerStyle: headerStyle
				}
			columns.unshift(pdIdColumn);
		}

		const tableRowEvents = {
			onClick: (e, row) => {
				this.props.history.push('/pddetails/' + row.pdId, {
					pdId: row.pdId
				})
			}
		};

		return (<div style={{margin: "1vh 1vw"}}>
			{
				<Container fluid={true}>
					<Row>
						<Col xs={12} md={12} lg={12}>
							<div className={"text-muted float-right m-2"}><FontAwesomeIcon icon={"sync"}/> SeaStar data is synced circa every 15 minutes.</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<BootstrapTable
								bootstrap4
								keyField='pdId'
								data={ this.prepareDataForDisplay(this.state.data) }
								columns={ columns }
								noDataIndication={ this.onNoDataLoaded }
								rowEvents={ tableRowEvents }
								rowStyle={rowStyle}
								defaultSorted={[
									hasITRole?
									{
										dataField: 'pdId',
										order: 'asc'
									}:{
										dataField: 'productName',
										order: 'asc'
									}
								]}
								filter={filterFactory()}
								hover
							/>
						</Col>
					</Row>
				</Container>
			}
		</div>);
	}
}
