import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment'
import React, {Fragment} from "react";
import {
	Button,
	Col,
	Container,
	OverlayTrigger,
	Row,
	Tab,
	Tabs,
	Tooltip
} from "react-bootstrap";
import Authentication from './Authentication'
import ComingSoon from "./ComingSoon";

import SeaStarPdAwsAccounts from "./SeaStarPdAwsAccounts";
import SeaStarPdUsers from "./SeaStarPdUsers";
import SeaStarPdDomains from "./SeaStarPdDomains";
import SeaStarPdGitlab from "./SeaStarPdGitlab";

import "./SeaStarPdDetails.css"

export class SeaStarPdDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			locale: window.navigator.language
		};
	};

	determineRolesOfLoggedInUser = (userObjectId, userRoles) => {
		const myRoles = [];
		if (userRoles.manager.some(manager => manager.objectId === userObjectId)){
			myRoles.push("PD Manager");
		}
		if (userRoles.owner.some(owner => owner.objectId === userObjectId)){
			myRoles.push("PD Data Owner");
		}
		if (myRoles.length === 0){
			if (userRoles["member"].some(member => member.objectId === userObjectId)){
				myRoles.push("PD Member");
			}
		}
		return myRoles;
	};

	currentUserIsManagerOfPd = (pdData) => {
		const currentUserObjectId = Authentication.getUserObjectId();
		if (pdData.userRoles && Array.isArray(pdData.userRoles.manager)) {
			return pdData.userRoles.manager.some(manager => manager.objectId === currentUserObjectId);
		}
		return false;
	};

	currentUserIsOwnerOfPd = (pdData) => {
		const currentUserObjectId = Authentication.getUserObjectId();
		if (pdData.userRoles && Array.isArray(pdData.userRoles.owner)) {
			return pdData.userRoles.owner.some(owner => owner.objectId === currentUserObjectId);
		}
		return false;
	};

	currentUserIsMemberOfPd = (pdData) => {
		const currentUserObjectId = Authentication.getUserObjectId();
		if (pdData.userRoles && Array.isArray(pdData.userRoles.member)) {
			return pdData.userRoles.member.some(member => member.objectId === currentUserObjectId);
		}
		return false;
	};

	prepareDatumForDisplay = (datum) => {
		const preparedDatum = {}
		if (datum){
				preparedDatum.pdId = datum.pdId;

				if (datum.masterData !== undefined){
					let setupDateMoment = moment(datum.masterData.setupDateDDMMYYYY, "DDMMYYYY");
					if (setupDateMoment !== undefined){
						preparedDatum.setupDate = setupDateMoment.locale(this.state.locale).format("L"); //Localized Day-Month-Year display format
						preparedDatum.setupDateMsUtc = setupDateMoment.utc().valueOf();
					}else{
						preparedDatum.setupDate = undefined;
						preparedDatum.setupDateMsUtc = Number.MAX_SAFE_INTEGER;
					}
					preparedDatum.codeName = datum.masterData.codeName;
					preparedDatum.technicalReferenceName = datum.masterData.technicalReferenceName;
					preparedDatum.productName = datum.masterData.productName;
				}

				if (datum.team !== undefined){
					preparedDatum.microsoftTeamsTeamId = datum.team.teamId;
					preparedDatum.microsoftTeamsTeamDisplayName = datum.team.teamDisplayName;
					preparedDatum.microsoftTeamsTeamLastUpdateTimestampEpochMillis = datum.team.lastUpdateTimestampEpochMillis;
				}

				if (datum.awsAccounts !== undefined){
					preparedDatum.awsAccounts = datum.awsAccounts
				}else{
					preparedDatum.awsAccounts = [];
				}

				//userRoles may not be undefined. If they are, warnings will be displayed where appropriate, but the backend should already ignore PDs without userRoles
				if (datum.userRoles !== undefined){
					preparedDatum.userRolesManager = datum.userRoles.manager;
					preparedDatum.userRolesOwner = datum.userRoles.owner;
					preparedDatum.userRolesMember = datum.userRoles.member;
					const currentUserObjectId = Authentication.getUserObjectId();
					preparedDatum.myRoles = this.determineRolesOfLoggedInUser(currentUserObjectId, datum.userRoles);
					preparedDatum.currentUserIsManager = this.currentUserIsManagerOfPd(datum);
					preparedDatum.currentUserIsOwner = this.currentUserIsOwnerOfPd(datum);
					preparedDatum.currentUserIsMember = this.currentUserIsMemberOfPd(datum);
				}

				if(datum.domains !== undefined){
					preparedDatum.domains = datum.domains;
				}else{
					preparedDatum.domains = [];
				}

				if (datum.masterData.costCenter !== undefined) {
					preparedDatum.costCenter = datum.masterData.costCenter;
				} else {
					preparedDatum.costCenter = undefined;
				}

				preparedDatum.gitlabConfig = datum.gitlabConfig;


			}
		return preparedDatum;
	};

	render() {
		const pdId = this.props.location.state.pdId;
		let pd = {};
		if (this.props.data && Array.isArray(this.props.data.pds)){
			const pdRawData = this.props.data.pds.filter(pd => {return pd.pdId === pdId});
			if (pdRawData && pdRawData.length === 1){
				pd = this.prepareDatumForDisplay(pdRawData[0])
			}
		}

		return (
			<Container fluid={true} style={{marginTop: "1vh"}}>
				<Row className={"d-flex align-items-center"}>
					<Col xs={1} md={1} lg={1} className={"d-flex justify-content-start"}>
						<Button variant="dark" text="light" onClick={this.props.history.goBack}><FontAwesomeIcon icon="angle-left"/> Back</Button>
					</Col>
					<Col xs={11} md={11} lg={11} className={"d-flex justify-content-center"}>
						<OverlayTrigger
							key={"showCodenameOverlay"}
							placement={"bottom"}
							overlay={
								<Tooltip id={`showCodenameTooltip`}>
									Codename {pd.codeName}
								</Tooltip>
							}
						>
							<h2>{pd.productName}</h2>
						</OverlayTrigger>
					</Col>
				</Row>
				<Row>
					<Col>
						<Tabs fill style={{color: "black"}} defaultActiveKey="seastar-pd-user" className={"mt-3"}>
							<Tab style={{color: "black"}} eventKey="seastar-pd-user" title={<Fragment><img src={process.env.PUBLIC_URL + '/img/PIXIDA_Black_Red_Large.png'} height={23} className="d-inline-block align-center" alt="Pixida Compass"/> Team</Fragment>}>
								<Tab.Pane className={"rounded-bottom border-left border-right border-bottom border-medium"}>
									<SeaStarPdUsers pdManager={pd.userRolesManager} pdOwner={pd.userRolesOwner} pdMember={pd.userRolesMember} teamName={pd.microsoftTeamsTeamDisplayName} />
								</Tab.Pane>
							</Tab>
							<Tab eventKey="seastar-pd-aws-accounts" title={<Fragment><FontAwesomeIcon size="lg" icon={["fab","aws"]}/> AWS Accounts ({pd.awsAccounts !== undefined ? pd.awsAccounts.length:0}) </Fragment>}>
								<Tab.Pane className={"rounded-bottom border-left border-right border-bottom border-medium"}>
									<SeaStarPdAwsAccounts pdAwsAccounts={pd.awsAccounts} pdManager={pd.userRolesManager} currentUserIsMemberOrOwner={pd.currentUserIsOwner || pd.currentUserIsMember} pdName={pd.productName}/>
								</Tab.Pane>
							</Tab>
							<Tab eventKey="seastar-pd-domains" title={<Fragment><FontAwesomeIcon size="lg" icon={"server"}/> Domains ({pd.domains !== undefined ? pd.domains.length:0})</Fragment>}>
								<Tab.Pane className={"rounded-bottom border-left border-right border-bottom border-medium"}>
									<SeaStarPdDomains pdDomains={pd.domains} pdManager={pd.userRolesManager} pdName={pd.productName}/>
								</Tab.Pane>
							</Tab>
							<Tab eventKey="seastar-pd-jira-projects" title={<Fragment><FontAwesomeIcon size="lg" icon={["fab","jira"]}/> Jira Projects</Fragment>}>
								<Tab.Pane className={"rounded-bottom border-left border-right border-bottom border-medium"}>
									<ComingSoon backButton={false}/>
								</Tab.Pane>
							</Tab>
							<Tab eventKey="seastar-pd-gitlab-group" title={<Fragment><FontAwesomeIcon size="lg" icon={["fab","gitlab"]}/> Gitlab ({pd.gitlabConfig !== undefined ? 'on' : 'off'})</Fragment>}>
								<Tab.Pane className={"rounded-bottom border-left border-right border-bottom border-medium"}>
									<SeaStarPdGitlab pdGitlabConfig={pd.gitlabConfig} pdId={pd.pdId} pdManager={pd.userRolesManager} pdOwner={pd.userRolesOwner} pdMember={pd.userRolesMember} currentUserIsOwner={pd.currentUserIsOwner} currentUserIsMember={pd.currentUserIsMember}/>
								</Tab.Pane>
							</Tab>
						</Tabs>
						<br/>
					</Col>
				</Row>
			</Container>
		)
	}
}