import "moment/min/locales"
import React, {Fragment} from 'react';
import {Button, Col, Row, Container} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "./react-bootstrap-table-overwrites.css"
import MailHandler from "./MailHandler";

class UserList extends React.Component{
    render() {
        return (
            <div>
                {this.props.users.map(user => <Fragment key={user.objectId}>{user.displayName}<br/></Fragment>)}
            </div>
        )
    }
}

export class SeaStarDomains extends React.Component {
    constructor(props) {
        super(props);
        props.selectMainMenuItem('my-resources-dropdown');
        this.state = {
            data:props.data,
            locale: window.navigator.language
        };
    };

    componentDidMount = async () => {
    };

    prepareDataForDisplay = (data) => {
        const preparedData = [];
        if (data && data.pds){
            for (let index = 0; index < data.pds.length; index++) {
                let datum = data.pds[index];
                if(datum.domains !== undefined){
                    for(let indexJ = 0; indexJ < datum.domains.length; ++indexJ){
                        let preparedDatum = {
                            pdId: datum.pdId,
                            parentDomainName: datum.domains[indexJ].parentDomainName,
                            domainName: datum.domains[indexJ].domainName
                        };

                        if(datum.domains[indexJ].nameServers && datum.domains[indexJ].nameServers.length > 0){
                            preparedDatum.nameServers = datum.domains[indexJ].nameServers;
                        }else{
                            preparedDatum.nameServers = [];
                        }

                        if (datum.masterData !== undefined){
                            preparedDatum.productName = datum.masterData.productName;
                        }else{
                            preparedDatum.productName = 'Unknown';
                        }
                        preparedData.push(preparedDatum);
                    }
                }
            }
        }
        return preparedData;
    };

    onNoDataLoaded = () => {
        return (<div>No Data Available</div>)
    };

    formatUserCell = (cellContent) => {
        return (
            <div>
                {(cellContent !== undefined && cellContent.length > 0) ?
                    <Fragment>
                        <UserList users={cellContent}/>
                        <Button className="btn-sm" style={{ marginTop: '4px' }} variant="outline-dark" onClick={(event) => {
                            event.stopPropagation();
                            MailHandler.contactPdUsers(cellContent);
                        }}><FontAwesomeIcon icon={"envelope"}/> Contact All</Button>
                    </Fragment>
                    :
                    <Fragment>
                        <div className={"text-danger"}><FontAwesomeIcon icon={"exclamation-triangle"}/> No one defined for this role in this PD</div>
                    </Fragment>}
            </div>);
    };

    render() {
        const rowStyle = { cursor: 'pointer' };
        const headerStyle = {verticalAlign: "top"};

        const columns = [{
            id: "pdName",
            text: 'Product Name',
            dataField: "productName",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle
        },{
            id: "tldName",
            text: 'TLD Name',
            dataField: "parentDomainName",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle
        },{
            id: "domainName",
            text: 'Domain Name',
            dataField: "domainName",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle
        },{
            id: "nsName",
            text: 'Name Servers',
            dataField: "nameServers",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle,
            formatter: (nameServers) => {
                return (nameServers.map((nameServer) => <div>{nameServer}</div>));
            }
        }];

        return (<div style={{margin: "1vh 1vw"}}>
            {
                <Container fluid={true}>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <div className={"text-muted float-right m-2"}><FontAwesomeIcon icon={"sync"}/> SeaStar data is synced circa every 15 minutes.</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable
                                bootstrap4
                                keyField='domainName'
                                data={ this.prepareDataForDisplay(this.state.data) }
                                columns={ columns }
                                noDataIndication={ this.onNoDataLoaded }
                                rowStyle={rowStyle}
                                defaultSorted={[{
                                    dataField: 'productName',
                                    order: 'asc'
                                }]}
                                filter={filterFactory()}
                            />
                        </Col>
                    </Row>
                </Container>
            }
        </div>);
    }
}
