import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {Button, Card, Container} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import {withRouter} from "react-router-dom";
import MailHandler from "./MailHandler";

class SeaStarPdDomains extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    componentDidMount = async () => {
    };

    onNoDataLoaded = () => {
        return (<div>No Domains for this PD found</div>)
    };

    prepareDataForDisplay = (domains) => {
        const preparedData = [];
        if(domains !== undefined){
            for(let indexJ = 0; indexJ < domains.length; ++indexJ){
                let preparedDatum = {
                    parentDomainName: domains[indexJ].parentDomainName,
                    domainName: domains[indexJ].domainName
                };

                if(domains[indexJ].nameServers && domains[indexJ].nameServers.length > 0){
                    preparedDatum.nameServers = domains[indexJ].nameServers;
                }else{
                    preparedDatum.nameServers = [];
                }

                preparedData.push(preparedDatum);
            }
        }
        return preparedData;
    };

    render(){
        const headerStyle = {verticalAlign: "top"};

        const columns = [{
            id: "tldName",
            text: 'TLD Name',
            dataField: "parentDomainName",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle,
            isKeyField:true
        },{
            id: "domainName",
            text: 'Domain Name',
            dataField: "domainName",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle
        },{
            id: "nsName",
            text: 'Name Servers',
            dataField: "nameServers",
            sort: true,
            headerAlign: 'center',
            headerSortingStyle:{backgroundColor:"lightgray"},
            headerStyle: headerStyle,
            formatter: (nameServers) => {
                return (nameServers.map((nameServer) => <div>{nameServer}</div>));
            }
        }];

        const preparedData = this.prepareDataForDisplay((this.props.pdDomains) ? this.props.pdDomains : []);

        return (
            <Container fluid={true}>
                <Card style={{border: "none"}}>
                    <Card.Body>
                        <Card.Title>
                            <div className={"d-flex align-items-center"}>
                                <div className={"d-flex flex-grow-1 align-items-center"}>
                                </div>
                                <div>
                                    <Button variant="outline-dark" onClick={(event) => {
                                        event.stopPropagation();
                                        MailHandler.mailtoItToRequestNewPdDomain(this.props.pdManager, this.props.pdName);
                                    }}><FontAwesomeIcon icon={"envelope"}/> Request new Domain</Button>
                                </div>
                            </div>
                        </Card.Title>
                        <BootstrapTable
                            bootstrap4
                            keyField='domainName'
                            data={ preparedData }
                            columns={ columns }
                            noDataIndication={ this.onNoDataLoaded }
                            defaultSorted={[{
                                dataField: 'parentDomainName',
                                order: 'asc'
                            }]}
                        />
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default withRouter(SeaStarPdDomains);