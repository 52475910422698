import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  ListGroup,
  ListGroupItem,
  Collapse,
} from 'react-bootstrap'
import {merge} from 'lodash'

import "./Sidebar.css"

const sidebarVariantStyles = {
  menu: {
    border: '1px solid rgba(0, 0, 0, 0.1)'
  },
  toc: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
  }
}

const sidebarItemVariantStyles = {
  menu: {},
  toc: {
    fontSize: 14,
    paddingTop: 2,
    paddingBottom: 2
  }
}

function SidebarItem({ variant = 'menu', depthStep = 10, depth = 0, expanded = false, collapsible = true, item, activeItemId, ...rest }) {
  const { label, items, icon, href, onClick: onClickProp } = item

  const [isCollapsed, setCollapsed] = React.useState((Array.isArray(items) && items.length > 0) ? !expanded : true)
  const [wasUserAction, setUserAction] = React.useState(false)

  function toggleCollapse() {
    setUserAction(true)
    setCollapsed(prevValue => !prevValue)
  }

  function onClick(e) {
    if (Array.isArray(items) && items.length > 0 && collapsible) {
      toggleCollapse()
    }
    if (onClickProp) {
      onClickProp(e, item)
    }
  }

  function recursiveFlatten(array) {
    return array.reduce((accumulator, arrayItem) => {
      if (arrayItem.items) {
        return accumulator.concat(recursiveFlatten(arrayItem.items))
      } else {
        return accumulator.concat(arrayItem)
      }
    }, [])
  }

  let expandIcon = <React.Fragment/>

  if (Array.isArray(items) && items.length > 0 && collapsible) {
    if (!wasUserAction) {
      const activeChildren = recursiveFlatten(items).filter((child) => {return child.id === activeItemId})
      if (activeChildren && activeChildren.length > 0 && isCollapsed) {
        setCollapsed(false)
      }
    }

    expandIcon = !isCollapsed ? (
      <FontAwesomeIcon icon={'angle-down'} className={'mr-6'}/>
    ) : (
      <FontAwesomeIcon icon={'angle-left'}/>
    )
  }

  const listGroupItemStyle = merge({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 0
  },sidebarItemVariantStyles[variant] || sidebarItemVariantStyles['menu']);

  return (
    <React.Fragment>
      <ListGroupItem
        style={listGroupItemStyle}
        active={item.id === activeItemId}
        action={true}
        onClick={onClick}
        href={href}
        {...rest}
      >
        <div
          style={{
            paddingLeft: depth * depthStep,
            //whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <div style={{ width: '100%' }}>{icon} {label}</div>
        </div>
        {expandIcon}
      </ListGroupItem>
      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) && items.length > 0 ? (
          <ListGroup>
            {items && items.map((subItem, index) => (
              <React.Fragment key={`${subItem.id}${index}`}>
                {subItem.id === 'sideBarDivider' ? (
                  subItem.visible ? (
                    <hr className={'m-0'} style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}/>
                  ) : (<React.Fragment/>)
                ) : (
                  subItem.visible ? (<SidebarItem
                    variant={variant}
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                    activeItemId={activeItemId}
                    expanded={expanded}
                    collapsible={collapsible}
                  />) : (<React.Fragment/>)
                )}
              </React.Fragment>
            ))}
          </ListGroup>
        ) : (<React.Fragment/>)}
      </Collapse>
    </React.Fragment>
  )
}

function Sidebar({ variant = 'menu', items, activeItemId, depthStep, depth, expanded, collapsible }) {
  return (
    <ListGroup id={(sidebarVariantStyles[variant]) ? "sidebar-" + variant : "sidebar-menu"} style={sidebarVariantStyles[variant] || sidebarVariantStyles['menu']}>
      {items && items.map((sidebarItem, index) => (
        <React.Fragment key={`${sidebarItem.id}${index}`}>
          {sidebarItem.id === 'sideBarDivider' ? (
            sidebarItem.visible ? (
              <hr className={'m-0'} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}/>
            ) : (
              <React.Fragment/>
            )
          ) : (
            sidebarItem.visible ? (<SidebarItem
              variant={variant}
              depthStep={depthStep}
              depth={depth}
              expanded={expanded}
              collapsible={collapsible}
              item={sidebarItem}
              activeItemId={activeItemId}
            />) : (<React.Fragment/>)
          )}
        </React.Fragment>
      ))}
    </ListGroup>
  )
}

export default Sidebar