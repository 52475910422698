import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getDocumentationEntity } from './DocumentationEntities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DocumentationViewer from './DocumentationViewer'
import Sidebar from './Sidebar'

class SeaStarDocumentation extends React.Component {
  constructor(props) {
    super(props)
    props.selectMainMenuItem('documentation')

    this.state = {
      toc: []
    }

    this.userHandbookItemId = 'userHandbook'

    this.documentationItems = [
      {
        id: this.userHandbookItemId,
        label: 'User Handbook',
        documentationEntityName: 'sea-star-documents/user-handbook.md',
        icon: <FontAwesomeIcon icon={['fas', 'book-reader']}/>,
        onClick: this.onDocumentationItemClick,
        visible: false,
      },
      {
        id: 'sideBarDivider',
        visible: false,
      },
      {
        id: 'itWorkflows',
        label: 'IT Workflows',
        icon: <FontAwesomeIcon icon={['fas', 'pen']}/>,
        visible: false,
        items: [
          {
            id: 'itWorkflowPdMasterData',
            label: 'PD Master Data',
            documentationEntityName: 'sea-star-pd-master-data/it-workflows.md',
            visible: false,
            onClick: this.onDocumentationItemClick
          },
          {
            id: 'itWorkflowUserRoles',
            label: 'User Roles',
            documentationEntityName: 'sea-star-users-roles/it-workflows.md',
            visible: false,
            onClick: this.onDocumentationItemClick
          },
          {
            id: 'itWorkflowAwsAccounts',
            label: 'AWS Accounts',
            documentationEntityName: 'sea-star-aws-accounts/it-workflows.md',
            visible: false,
            onClick: this.onDocumentationItemClick
          },
          {
            id: 'itWorkflowAwsFunctionUsers',
            label: 'AWS Function Users',
            documentationEntityName: 'sea-star-function-users/it-workflows.md',
            visible: false,
            onClick: this.onDocumentationItemClick
          },
          {
            id: 'itWorkflowAwsPlaygrounds',
            label: 'AWS Playgrounds',
            documentationEntityName: 'sea-star-aws-playground/it-workflows.md',
            visible: false,
            onClick: this.onDocumentationItemClick
          },
          {
            id: 'itWorkflowDomains',
            label: 'Domains',
            documentationEntityName: 'sea-star-domains/it-workflows.md',
            visible: false,
            onClick: this.onDocumentationItemClick
          },
          {
            id: 'itWorkflowJira',
            label: 'Jira',
            documentationEntityName: 'sea-star-jira/it-workflows.md',
            visible: false,
            onClick: this.onDocumentationItemClick
          }
        ]
      },
      {
        id: 'sideBarDivider',
        visible: true,
      },
      {
        id: 'itDocumentation',
        label: 'IT Documentation',
        icon: <FontAwesomeIcon icon={['fas', 'user-lock']}/>,
        visible: false,
        items: [
          {
            id: 'itDocumentationRolesAndRightsConcept',
            label: 'Roles and Rights Concept',
            documentationEntityName: 'sea-star-users-roles/roles-rights-concept.md',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'itDocumentationAccountInfrastructure',
            label: 'Account Infrastructure',
            documentationEntityName: 'seaStarSharedServicesNetwork',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
        ]
      },
      {
        id: 'sideBarDivider',
        visible: true,
      },
      {
        id: 'devDocumentation',
        label: 'Dev Documentation',
        icon: <FontAwesomeIcon icon={['fab', 'dev']} className={'fa-lg'}/>,
        visible: false,
        items: [
          {
            id: 'devDocumentationAwsAccountsService',
            label: 'AWS Accounts Service',
            documentationEntityName: 'serviceDocSeaStarAwsAccounts',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationAwsLogsService',
            label: 'AWS Logs Service',
            documentationEntityName: 'serviceDocSeaStarAwsLogs',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationAwsPlaygroundService',
            label: 'AWS Playgrounds Service',
            documentationEntityName: 'serviceDocSeaStarAwsPlayground',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationCertificatesService',
            label: 'Certificates Service',
            documentationEntityName: 'serviceDocSeaStarCertificates',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationDashboard',
            label: 'Dashboard',
            documentationEntityName: 'serviceDocSeaStarDashboard',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationDocumentsService',
            label: 'Documents Service',
            documentationEntityName: 'serviceDocSeaStarDocuments',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationDomainsService',
            label: 'Domains Service',
            documentationEntityName: 'serviceDocSeaStarDomains',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationGitlabService',
            label: 'Gitlab Service',
            documentationEntityName: 'serviceDocSeaStarGitlab',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationGitlabRunnerService',
            label: 'Gitlab Runner Service',
            documentationEntityName: 'serviceDocSeaStarGitlabRunner',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationMailingLists',
            label: 'Mailing Lists',
            documentationEntityName: 'serviceDocSeaStarMailingLists',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationPdMasterData',
            label: 'PD Master Data Service',
            documentationEntityName: 'serviceDocSeaStarPdMasterData',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationPlantUml',
            label: 'PlantUML',
            documentationEntityName: 'serviceDocSeaStarPlantUml',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationSonarqube',
            label: 'SonarQube Service',
            documentationEntityName: 'serviceDocSeaStarSonarqube',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
          {
            id: 'devDocumentationUserRoles',
            label: 'User Roles Service',
            documentationEntityName: 'serviceDocSeaStarUserRoles',
            onClick: this.onDocumentationItemClick,
            visible: false,
          },
        ]
      }
    ]
  };

  onDocumentationItemClick = (event, item) => {
    this.props.history.push(this.props.match.url + '/' + item.id)
  }

  recursiveFlatten(items) {
    return items.reduce((accumulator, item) => {
      if (item.items) {
        return accumulator.concat(this.recursiveFlatten(item.items))
      } else {
        return accumulator.concat(item)
      }
    }, [])
  }

  getDocumentationEntityFromItemId = (itemId) => {
    const documentationItem = this.recursiveFlatten(this.documentationItems).filter((item) => {return item.id === itemId})
    if (documentationItem && documentationItem.length >= 1) {
      return getDocumentationEntity(documentationItem[0].documentationEntityName)
    }
  }

  recursiveSetVisibility(item) {
    if (item === 'divider') {
      return false
    }

    if (item.items && item.items.length > 0) {
      for (const subItem of item.items) {
        // Recursive call needs to be in his own line, otherwise the function is only called for the first subItem.
        const subItemIsVisible = this.recursiveSetVisibility(subItem)
        item.visible = item.visible || subItemIsVisible
      }
    }

    if (item.documentationEntityName) {
      item.visible = getDocumentationEntity(item.documentationEntityName) !== null
    }

    return item.visible
  }

  setVisibilityAndGetDocumentationItems() {
    let showNextDivider = true

    for (const documentationItem of this.documentationItems) {
      if (documentationItem.id !== 'sideBarDivider') {
        documentationItem.visible = this.recursiveSetVisibility(documentationItem)
        showNextDivider = documentationItem.visible
      } else {
        documentationItem.visible = showNextDivider
      }
    }

    return this.documentationItems
  }

  render() {
    return (
      <Switch>
        <Route
          path={this.props.match.path + '/:id'}
          render={(props) => {
            const anchor = (props.location) ? props.location.hash : undefined;
            const entity = (props.match && props.match.params) ? this.getDocumentationEntityFromItemId(props.match.params.id) : undefined;

            return (
              <div className="d-flex flex-row justify-content-start">
                <div className={"sticky-top sidebar-mainmenu-offset p-2"} style={{ zIndex: "998", width: '16%', minWidth:"16%", maxWidth:"16%"}}>
                  <Sidebar
                    variant={"menu"}
                    items={this.setVisibilityAndGetDocumentationItems()}
                    activeItemId={props.match.params.id}/>
                </div>
                <div className={"pl-2 pt-2 pr-2"} style={{ width: '84%', minWidth:"84%", maxWidth:"84%"}}>
                  <DocumentationViewer
                    documentationEntity={entity}
                    anchor={anchor}
                  />
                </div>
              </div>
            )
          }
          }
        />
        <Route
          path={this.props.match.path}
          render={(props) => <Redirect to={{ pathname: props.match.path + '/' + this.userHandbookItemId }}/>}
        />
      </Switch>
    )
  }
}

export default SeaStarDocumentation