import { getDocumentationEntity } from './DocumentationEntities';

class MailHandler {
	static itSupportEmailAddress = "mailto:IT Service Desk <it-support@pixida.com>"

	static mailTo = (mailtoUri) => {
		const mail = document.createElement("a");
		mail.href = mailtoUri;

		// only solution that works in Chrome, Firefox and Edge! Calling click() function does not work in Firefox
		const clickEvent = new MouseEvent("click", {
			"view": window,
			"bubbles": true,
			"cancelable": false
		});

		mail.dispatchEvent(clickEvent);
	};

	static mailtoItToRequestNewSeaStarPd = () => {
		const subject = "Request for new SeaStar PD [Insert here your product name]";
		const bodyTemplate = getDocumentationEntity("RequestPdEmail");
		if (bodyTemplate !== null) {
			let mailToUri = MailHandler.itSupportEmailAddress + "?body=" + encodeURIComponent(bodyTemplate) + "&subject=" + encodeURIComponent(subject);
			MailHandler.mailTo(mailToUri);
		}
	};

	static mailtoItToRequestAwsAccount = (seaStarUsersToCc, pdName) => {
		let ccAddresses = "";
		if (seaStarUsersToCc){
			ccAddresses = seaStarUsersToCc.reduce((aggregatedValue, currentValue) => {
				return aggregatedValue + currentValue.displayName+" <"+currentValue.emailAddress+">;"
			}, "");
		}

		let teamplatePdName = "[Insert here your SeaStar product name]";
		if (pdName && pdName.length > 0){
			teamplatePdName = pdName;
		}

		const subject = "Request new AWS Account for PD " + teamplatePdName;
		const bodyTemplate = getDocumentationEntity("RequestNewAwsAccountEmail");
		const body = bodyTemplate.replace(/\[PD_NAME\]/g, pdName);
		if (bodyTemplate !== null) {
			let mailToUri = MailHandler.itSupportEmailAddress + "?body=" + encodeURIComponent(body) + "&subject=" + encodeURIComponent(subject) + "&cc=" + ccAddresses;
			MailHandler.mailTo(mailToUri);
		}
	};
	
	static mailtoItToUpdateCostCenterOfPd = (costCenterValueValid, costCenterValue, pdName) => {
		let teamplatePdName = "[Insert here your SeaStar product name]";
		if (pdName && pdName.length > 0){
			teamplatePdName = pdName;
		}
		
		const subject = "Update Cost Center for PD " + teamplatePdName;
		const bodyTemplate = getDocumentationEntity("RequestUpdateOfPdCostCenterEmail");
		const body = bodyTemplate.replace(/\[PD_NAME\]/g, pdName)
			.replace(/\[CURRENT_COST_CENTER\]/g, costCenterValueValid ? "\"" + costCenterValue + "\"" : "(none)");
		if (bodyTemplate !== null) {
			let mailToUri = MailHandler.itSupportEmailAddress + "?body=" + encodeURIComponent(body) + "&subject=" + encodeURIComponent(subject);
			MailHandler.mailTo(mailToUri);
		}
	};

	static mailtoItToRequestAwsAccountBudgetUpdate = (awsAccountId) => {
		const subject = "Request for AWS Account Budget Update";
		const bodyTemplate = getDocumentationEntity("RequestAwsAccountBudgetUpdateEmail");
		if (bodyTemplate !== null) {
			const body = bodyTemplate.replace(/\[AWS_ACCOUNT_ID\]/g, awsAccountId);
			let mailToUri = MailHandler.itSupportEmailAddress + "?body=" + encodeURIComponent(body) + "&subject=" + encodeURIComponent(subject);
			MailHandler.mailTo(mailToUri);
		}
	};

	static mailtoItToRequestNewPdDomain = (seaStarUsersToCc, pdName) => {
		let ccAddresses = "";
		if (seaStarUsersToCc){
			ccAddresses = seaStarUsersToCc.reduce((aggregatedValue, currentValue) => {
				return aggregatedValue + currentValue.displayName+" <"+currentValue.emailAddress+">;"
			}, "");
		}

		let teamplatePdName = "[Insert here your SeaStar product name]";
		if (pdName && pdName.length > 0){
			teamplatePdName = pdName;
		}

		const subject = "Request new domain PD " + teamplatePdName;
		const bodyTemplate = getDocumentationEntity("RequestNewDomainEmail");
		const body = bodyTemplate.replace(/\[PD_NAME\]/g, pdName);
		if (bodyTemplate !== null) {
			let mailToUri = MailHandler.itSupportEmailAddress + "?body=" + encodeURIComponent(body) + "&subject=" + encodeURIComponent(subject) + "&cc=" + ccAddresses;
			MailHandler.mailTo(mailToUri);
		}
	};

	static contactPdUsers = (users) => {
		const mailToUri = "mailto:" + users.reduce((aggregatedValue, currentValue) => {
			return aggregatedValue + currentValue.displayName+" <"+currentValue.emailAddress+">;"
		}, "");

		this.mailTo(mailToUri)
	};

	static sendFeatureRequest = () => {
		const mailToUri = "mailto:Feature Requests - Sea Star Platform Development <b1ac657d.pixida.com@emea.teams.ms>;Sea Star Platform Developers <sea-star-platform-developers@pixida.com>?subject=[Feature Request]";
		MailHandler.mailTo(mailToUri);
	};

	static sendBugReport = () => {
		const mailToUri = "mailto:Bug Reports - Sea Star Platform Development <7b73adc8.pixida.com@emea.teams.ms>;Sea Star Platform Developers <sea-star-platform-developers@pixida.com>?subject=[Bug Report]";
		MailHandler.mailTo(mailToUri);
	};
}

export default MailHandler;