import crypto from 'crypto';
import jwt_decode from 'jwt-decode';
import * as queryString from 'query-string'
import Cookies from 'js-cookie'

//TODO: Put entire authorization code grant flow into backend, only exposing the app to the redirect and the token it needs
// -> Otherwise security features have to be stored for a limited time in localStorage due to redirect during authorization code grant flow.
const Authentication = {

	CLIENT_ID: '252ntf762pur8ri337t30cqhss',
	REDIRECT_URI: window.location.protocol+'//'+ window.location.hostname+(window.location.port ? ':'+ window.location.port: ''),

	SESSION_INVALIDATED: 0,
	USER_LOGGED_OUT: 1,

	UNKNOWN_SERVER_ERROR: 2,
	CONNECTION_ERROR: 3,
	REJECTED: 4,

	codeVerifier: undefined,
	codeChallenge: undefined,
	codeChallengeMethod: "S256", //AWS Cognito only accepts SHA-256 as code challenge hashing method

	access_token: undefined,
	id_token: undefined,
	refresh_token: undefined,
	decoded_access_token: undefined,
	decoded_id_token: undefined,

	onAuthenticationFailed: undefined,
	onLoginInitialized: undefined,
	onSessionInactive: undefined,
	onSessionStarted: undefined,
	onSessionContinued: undefined,

	// Internal timer to frequently check the token for expiration and prolong it, if necessary
	checkTokenInterval: undefined,
	REFRESH_TOKEN_MIN_REMAINING_TIME_MS: 5 * 60 * 1000, //5 minutes
	CHECK_TOKEN_INTERVAL_TIME_MS: 60 * 1000, // 1 minute

	// Token to transmit logout requests to other sessions as well, for security reasons
	// Should be a non-valid base64 encoded string to prevent being interpreted as token
	LOGOUT_STORAGE_TOKEN: "$",
	EMPTY_CODE_CHALLENGE_IN_STORAGE: "$",

	init(){
		this.tryToObtainTokenFromStorage();
	},

	getUserName(){
		if (this.decoded_id_token){
			return this.decoded_id_token.given_name + " " + this.decoded_id_token.family_name;
		}else {
			console.error("ID Token does not exist. Unknown User.");
			return "Unknown User"
		}
	},

	getUserObjectId(){
		if (this.decoded_id_token){
			return this.decoded_id_token["custom:ObjectID"];
		}else {
			console.error("ID Token does not exist. Unknown User.");
			return "Unknown User"
		}
	},

	/**
	 *
	 * @returns {string|*} either "user" or "[user, ceo, it] for any combination of roles
	 */
	getUserRolesString(){
		if (this.decoded_id_token){
			return this.decoded_id_token["custom:Roles"];
		}else {
			console.error("ID Token does not exist. Unknown User.");
			return "Unknown User"
		}
	},

	hasRole(role){
		let result  = false;

		if(role !== undefined){
			const roles = this.getUserRoles();
			if (Array.isArray(roles)){
				result = roles.includes(role);
			}else{
				result = roles === role;
			}
		}

		return result;
	},

	getUserRoles() {
		let userRolesString = Authentication.getUserRolesString();
		return userRolesString.match(/\w+/ig);
	},

	getBearerAuthHeader(){
		return "Bearer " + this.id_token;
	},

	async handleUserLoggedIn(location) {
		const parsedQueryString = this.getParsedQueryString(location);
		if (parsedQueryString && parsedQueryString.code && parsedQueryString.state){
			if (!this.stateInQueryStringIsVerified(parsedQueryString.state)){
				return;
			}

			const token = await this.getTokenFromCognito(parsedQueryString.code);
			if (token){
				const accessToken = token.access_token;
				const idToken = token.id_token;
				const refreshToken = token.refresh_token;
				this.updateToken(accessToken, idToken, refreshToken);
			}
		}
	},

	stateInQueryStringIsVerified(queryStringState){
		const storedState = this.tryToObtainAndDeleteStateFromLocalStorage();

		return storedState !== undefined && storedState === queryStringState;
	},

	async getTokenFromCognito(authorization_code){
		this.tryToObtainCodeChallengeFromStorage();

		if (this.codeVerifier === this.EMPTY_CODE_CHALLENGE_IN_STORAGE){
			return;
		}

		try {
			const response = await fetch("https://sea-star-dashboard.auth.eu-central-1.amazoncognito.com/oauth2/token",
				{
					method: "POST",
					credentials:"include",
					headers: new Headers({
						'Content-Type': 'application/x-www-form-urlencoded'
					}),
					body: new URLSearchParams({
						'grant_type': 'authorization_code',
						'client_id': this.CLIENT_ID,
						'code': authorization_code,
						'code_verifier': this.codeVerifier,
						'redirect_uri': this.REDIRECT_URI
					}),
				});

			this.tryToRemoveCodeChallengeFromStorage();

			if (!response.ok){
				this.handleAuthenticationError(response);
				return;
			}

			return await response.json();
		}catch(error){
			this.handleConnectionError(error)
		}
	},

	async refreshAccessAndIdToken(){
		const token = await this.refreshTokenFromCognito();
		if (token) {
			const accessToken = token.access_token;
			const idToken = token.id_token;

			//No new refresh token, reuse old
			this.updateToken(accessToken, idToken, this.refresh_token)
		}
	},

	async refreshTokenFromCognito(){
		try {
			const response = await fetch("https://sea-star-dashboard.auth.eu-central-1.amazoncognito.com/oauth2/token",
				{
					method: "POST",
					credentials: "include",
					headers: new Headers({
						'Content-Type': 'application/x-www-form-urlencoded'
					}),
					body: new URLSearchParams({
						'grant_type': 'refresh_token',
						'client_id': this.CLIENT_ID,
						'refresh_token': this.refresh_token
					}),
				});

			if (!response.ok){
				this.handleAuthenticationError(response);
				return;
			}

			return await response.json();
		}catch(error){
			this.handleConnectionError(error);
			return undefined;
		}
	},

	updateToken(accessToken, idToken, refreshToken){
		let decodedAccessToken;
		let decodedIdToken;
		try {
			//decodedTokenHeader = jwt_decode(token, {header: true});
			//decodedIdTokenHeader = jwt_decode(token, {header: true});

			decodedAccessToken = jwt_decode(accessToken);
			decodedIdToken = jwt_decode(idToken);
		} catch (e) {
			console.log("Got invalid token (unable to decode: '" + e + "')");
			return {'tokenWasAccepted': false};
		}

		const oldAccessTokenWasValid = this.checkToken(this.decoded_access_token);
		const newAccessTokenIsValid = this.checkToken(decodedAccessToken);

		const oldIdTokenWasValid = this.checkToken(this.decoded_id_token);
		const newIdTokenIsValid = this.checkToken(decodedIdToken);

		let updateAccessToken = true;
		let updateIdToken = true;

		// Never discard a valid token
		if (newAccessTokenIsValid && newIdTokenIsValid) {
			// Never replace a token against one that expires ealier
			if (oldAccessTokenWasValid) {
				if (decodedAccessToken.exp <= this.decoded_access_token.exp) {
					console.log("Access token not updated as the new one expires earlier or at the same time as the current one");
					updateAccessToken = false;
				}
			}
			if (oldIdTokenWasValid) {
				if (decodedIdToken.exp <= this.decoded_id_token.exp) {
					console.log("ID token not updated as the new one expires earlier or at the same time as the current one");
					updateIdToken = false;
				}
			}

			if (updateAccessToken && updateIdToken){
				console.log("Updating token");

				this.endSessionWithoutCallingCallbacks();

				this.access_token = accessToken;
				this.decoded_access_token = decodedAccessToken;

				this.id_token = idToken;
				this.decoded_id_token = decodedIdToken;

				this.refresh_token = refreshToken;

				this.checkTokenInterval = setInterval(() => this.regularTokenCheck(), this.CHECK_TOKEN_INTERVAL_TIME_MS);

				if (typeof Storage !== "undefined") {
					// TODO change to only store the idtoken in the cookie?
					localStorage.setItem("access_token", accessToken);
					localStorage.setItem("id_token", idToken);
					localStorage.setItem("refresh_token", refreshToken);
					Cookies.set('token', idToken, {secure: true, samesite: 'Lax', domain: (window.location.hostname.indexOf("seastar.pixida.com") !== -1) ? '.seastar.pixida.com' : window.location.hostname});
				}
			}else{
				console.warn("Access token and ID token have different validity. Did not update any token.")
			}

			const fromPathname = this.tryToObtainAndDeleteFromPathnameFromLocalStorage()

			// Call callback functions
			if (oldAccessTokenWasValid || oldIdTokenWasValid) {
				if (this.onSessionContinued !== undefined) {
					console.log("Session continuing...");
					this.onSessionContinued(fromPathname);
				}
			} else {
				if (this.onSessionStarted !== undefined) {
					console.log("Session starting...");
					this.onSessionStarted(fromPathname);
				}
			}
		} else {
			console.log("At least one new token is not valid. Ignoring all new token.");
		}
	},

	regularTokenCheck() {
		if (!this.checkToken(this.decoded_access_token) || !this.checkToken(this.decoded_id_token)) {
			this.endSessionWithoutCallingCallbacks();
			if (this.onSessionInactive !== undefined) {
				this.onSessionInactive(this.SESSION_INVALIDATED);
			}
		}else{
			// If session will become invalid soon, try to refresh token
			const remainingAccessTokenTime = this.getRemainingTokenTime(this.decoded_access_token);
			const remainingIdTokenTime = this.getRemainingTokenTime(this.decoded_id_token);

			if ((remainingAccessTokenTime > 0 && remainingAccessTokenTime < this.REFRESH_TOKEN_MIN_REMAINING_TIME_MS) ||
				(remainingIdTokenTime > 0 && remainingIdTokenTime < this.REFRESH_TOKEN_MIN_REMAINING_TIME_MS)) {
				this.refreshAccessAndIdToken();
			}
		}
	},

	checkToken(decodedToken) {
		if (decodedToken === undefined) {
			return false;
		}
		if (decodedToken === this.LOGOUT_STORAGE_TOKEN) {
			return false;
		}

		let valid = true;

		// Check if token has not yet expired
		if (!('iat' in decodedToken)) {
			console.log("Found token which contains no 'issued at' information");
			valid = false;
		}
		if (!('exp' in decodedToken)) {
			// We should not inform the user about this.
			// console.log("Found token which does not expire");
			valid = false;
		}
		if (this.getRemainingTokenTime(decodedToken) <= 0) {
			console.log("Found an expired token");
			valid = false;
		}

		return valid;
	},

	getRemainingTokenTime(decodedToken) {
		const expMs = decodedToken.exp * 1000; // .exp = Seconds since epoche
		return expMs - (new Date()).getTime();
	},

	isSessionActive() {
		return this.checkToken(this.decoded_access_token) && this.checkToken(this.decoded_id_token);
	},

	// The name of this method only explicitly mentions that NO callbacks are called, so the method can be used to perform different session ending scenarios
	endSessionWithoutCallingCallbacks() {
		if (this.checkTokenInterval !== undefined) {
			clearInterval(this.checkTokenInterval);
			this.checkTokenInterval = undefined;
		}

		this.access_token = undefined;
		this.id_token = undefined;
		this.refresh_token = undefined;
		this.decoded_access_token = undefined;
		this.decoded_id_token = undefined;
	},

	login(from) {
		this.createCodeChallengeAndStoreCodeVerifierInStorage();
		const state = this.createAndStoreRandomState(from);
		this.storeFromPathname(from);

		const urlParameter = new URLSearchParams({
			'identity_provider': 'PixidaOffice365IdentityProvider',
			'redirect_uri': this.REDIRECT_URI,
			'response_type': 'CODE',
			'client_id': this.CLIENT_ID,
			'state': state,
			'code_challenge': this.codeChallenge,
			'code_challenge_method': this.codeChallengeMethod,
			'scope': 'openid profile'
		});

		if(this.onLoginInitialized !== false){
			this.onLoginInitialized();
		}

		window.location.href = "https://sea-star-dashboard.auth.eu-central-1.amazoncognito.com/oauth2/authorize?" + urlParameter.toString();
	},

	async logout() {
		if (typeof Storage !== "undefined") {
			localStorage.setItem("access_token", this.LOGOUT_STORAGE_TOKEN);
			localStorage.setItem("id_token", this.LOGOUT_STORAGE_TOKEN);
			localStorage.setItem("refresh_token", this.LOGOUT_STORAGE_TOKEN);
			localStorage.removeItem("access_token");
			localStorage.removeItem("id_token");
			localStorage.removeItem("refresh_token");
			localStorage.removeItem("state");
			localStorage.removeItem("codeVerifier");
			localStorage.removeItem("fromPathname");

			Cookies.set("token", this.LOGOUT_STORAGE_TOKEN);
			Cookies.remove("token");
		}
		this.endSessionWithoutCallingCallbacks();
		if (this.onSessionInactive !== false) {
			this.onSessionInactive(this.USER_LOGGED_OUT);
		}
	},

	getParsedQueryString(location) {
		let parsedQueryString = undefined;
		if (location && location.search){
			parsedQueryString = queryString.parse(location.search);
		}
		return parsedQueryString;
	},

	tryToObtainTokenFromStorage() {
		if (typeof Storage !== "undefined") {
			const storedAccessToken = localStorage.getItem("access_token");
			const storedIdToken = localStorage.getItem("id_token");
			const storedRefreshToken = localStorage.getItem("refresh_token");
			if (storedAccessToken && storedIdToken && storedRefreshToken) {
				if (storedAccessToken === this.LOGOUT_STORAGE_TOKEN || storedIdToken === this.LOGOUT_STORAGE_TOKEN || storedRefreshToken === this.LOGOUT_STORAGE_TOKEN) {
					this.logout();
				} else {
					if (storedAccessToken !== this.access_token || storedIdToken !== this.id_token || storedRefreshToken !== this.refresh_token) {
						console.log("Found " + (this.token === false ? "a" : "another") + " token in storage");
						this.updateToken(storedAccessToken, storedIdToken, storedRefreshToken);
					}
				}
			}
		}
	},

	createCodeChallengeAndStoreCodeVerifierInStorage() {
		let codeVerifierBuffer = new Buffer(crypto.randomBytes(32));
		this.codeVerifier = this.base64URLEncode(codeVerifierBuffer);
		let codeChallengeBuffer = new Buffer(crypto.createHash('sha256').update(this.codeVerifier).digest());
		this.codeChallenge = this.base64URLEncode(codeChallengeBuffer);

		//Store Code Verifier in Storage for token request after login/redirect
		if (typeof Storage !== "undefined") {
			localStorage.setItem("codeVerifier", this.codeVerifier);
		}
	},

	storeFromPathname(from){
		if (from && from.pathname){
			if (typeof Storage !== "undefined") {
				localStorage.setItem("fromPathname", from.pathname);
			}
		}
	},

	tryToObtainAndDeleteFromPathnameFromLocalStorage(){
		let storedPathname = undefined;
		if (typeof Storage !== "undefined") {
			storedPathname = localStorage.getItem("fromPathname");
			localStorage.setItem("fromPathname", this.EMPTY_CODE_CHALLENGE_IN_STORAGE);
			localStorage.removeItem("fromPathname");
		}
		return storedPathname;
	},

	createAndStoreRandomState(){
		let randomState = this.base64URLEncode(crypto.randomBytes(32));
		if (typeof Storage !== "undefined") {
			localStorage.setItem("state", randomState);
		}

		return randomState;
	},

	base64URLEncode(buffer) {
		return buffer.toString('base64')
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=/g, '');
	},

	tryToObtainCodeChallengeFromStorage(){
		if (typeof Storage !== "undefined") {
			this.codeVerifier = localStorage.getItem("codeVerifier");
		}
	},

	tryToRemoveCodeChallengeFromStorage(){
		if (typeof Storage !== "undefined") {
			localStorage.setItem("codeVerifier", this.EMPTY_CODE_CHALLENGE_IN_STORAGE);
			localStorage.removeItem("codeVerifier");

			this.codeVerifier = undefined;
			this.codeChallenge = undefined;
		}
	},

	tryToObtainAndDeleteStateFromLocalStorage(){
		let storedState = undefined;
		if (typeof Storage !== "undefined") {
			storedState = localStorage.getItem("state");
			localStorage.setItem("state", this.EMPTY_CODE_CHALLENGE_IN_STORAGE);
			localStorage.removeItem("state");
		}
		return storedState;
	},

	handleAuthenticationError(response){
		this.endSessionWithoutCallingCallbacks();

		console.error('GetTokenRequest failed with http ' + response.status + ': ' + response.statusText);

		if (response.status === 401 || response.status === 403) {
			if (this.onAuthenticationFailed !== false) {
				this.onAuthenticationFailed(this.REJECTED);
			}
		}else{
			if (this.onAuthenticationFailed !== false) {
				this.onAuthenticationFailed(this.UNKNOWN_SERVER_ERROR);
			}
		}
	},

	handleConnectionError(error){
		this.endSessionWithoutCallingCallbacks();

		console.error(error);

		if (this.onAuthenticationFailed !== false) {
			this.onAuthenticationFailed(this.CONNECTION_ERROR)
		}
	}
};

Authentication.init();
export default Authentication;